import { Attributes } from "../../types/Attributes";
import {
  DatabaseItem,
  ItemTag,
  RefinedMaterialItemType,
} from "../../types/Items";
import { refinedAnimalItems } from "./AnimalRefined";
import { refinedCrystalItems } from "./CrystalRefined";
import { refinedMetalItems } from "./MetalRefined";
import { refinedWoodItems } from "./WoodRefined";
import { Element } from "./Element";

// Refined Materials
export interface RefinedMaterialDatabase extends DatabaseItem {
  attributes: Attributes;
  elements: { [key in Element]?: number };
  craftedName: string;
  craftedDescription: string;
  craftedTags: ItemTag[];
}

export const refinedMaterialTotalAttributes = 4; // Exported for testing

export const refinedMaterialItems: {
  [key in RefinedMaterialItemType]: RefinedMaterialDatabase;
} = {
  // Refined Materials - Must have "attributes" {total 4}
  ...refinedAnimalItems,
  ...refinedCrystalItems,
  ...refinedMetalItems,
  ...refinedWoodItems,
};
