import { BasicContext } from "../context/BasicContext";
import { useContext } from "react";

export const useBasicContext = () => {
  const context = useContext(BasicContext);

  if (!context) {
    throw Error("useBasicContext must be used inside an BasicContextProvider");
  }

  return context;
};
