import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../../firebase/config";
import {
  CreateGovernmentData,
  govNameClean,
  validateGovName,
} from "../../types/Governments";

export default function CreateGovernment() {
  const [name, setName] = useState("");
  const [error, setError] = useState(null as string | null);
  const [isPending, setIsPending] = useState(false);

  const handleCreateGov = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(null);
    setIsPending(true);

    const cleanedName = govNameClean(name);
    setName(cleanedName);

    try {
      validateGovName(cleanedName);
    } catch (error) {
      if (typeof error === "string") {
        setError(error);
      } else {
        console.log(error);
        setError("Invalid government name");
      }
      setIsPending(false);
      return;
    }

    const createGov = httpsCallable(
      functions,
      "createGovernment"
    ) as HttpsCallable<CreateGovernmentData, unknown>;

    createGov({ name: cleanedName }).then((response) => {
      console.log(response);
      setError(null);
      setIsPending(false);
    });
  };

  return (
    <form onSubmit={handleCreateGov}>
      <h2>Create Government</h2>
      <label>
        <span>Name of your new government</span>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button className="btn" disabled={isPending}>
          Submit
        </button>
        {error && <p className="error">{error}</p>}
      </label>
    </form>
  );
}
