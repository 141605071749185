import { DatabaseItem, ScanItemType } from "../../types/Items";

export interface ScanItemDatabase extends DatabaseItem {}

export const scanItems: {
  [key in ScanItemType]: ScanItemDatabase;
} = {
  scan_animal: {
    name: "Animal Scan",
    description: "This scan contains location data about animals.",
    tags: ["animal", "scan", "resource"],
  },
  scan_crystal: {
    name: "Crystal Scan",
    description: "This scan contains location data about a crystal vein.",
    tags: ["crystal", "scan", "resource"],
  },
  scan_metal: {
    name: "Metal Scan",
    description: "This scan contains location data about scrap metal.",
    tags: ["metal", "scan", "resource"],
  },
  scan_wood: {
    name: "Wood Scan",
    description: "This scan contains location data about woods.",
    tags: ["wood", "scan", "resource"],
  },
};
