import { limit, orderBy, where } from "firebase/firestore";
import { useBasicContext } from "../../hooks/useBasicContext";
import { useCollection } from "../../hooks/useCollection";
import { renderCustomMarks } from "../../modules/CustomMarks";
import { LogData } from "../../types/Logs";
import "./Logs.css";

interface LogDocumentData extends LogData<any> {
  id: string;
}

type CollectionData = {
  documents: LogDocumentData[] | null;
  error: string | null;
};

export default function Logs() {
  const { basic } = useBasicContext();
  const { documents, error } = useCollection(`players/${basic.owner}/logs`, [
    orderBy("timestamp", "desc"),
    limit(25),
  ]) as CollectionData;

  return (
    <>
      <h1>Logs</h1>
      {!error && documents && (
        <ul>
          {documents.map((doc) => {
            const timestamp =
              doc.timestamp === undefined
                ? "No timestamp"
                : new Date(doc.timestamp.toMillis()).toLocaleString();

            const title =
              doc.request?.task === undefined ? doc.type : doc.request.task;

            return (
              <li key={doc.id} className="log">
                <h2>{title}</h2>
                <p>{timestamp}</p>
                <p>{renderCustomMarks(doc.message)}</p>
              </li>
            );
          })}
        </ul>
      )}
      {error && <p>{error}</p>}
    </>
  );
}
