import { ItemTag, RefinedCrystalType } from "../../types/Items";
import { RefinedMaterialDatabase } from "./RefinedMaterials";

const crystalTags: ItemTag[] = ["crystal"];
const refinedMaterialTags: ItemTag[] = ["refined"];

export const refinedCrystalItems: {
  [key in RefinedCrystalType]: RefinedMaterialDatabase;
} = {
  garnet_gem: {
    name: "Cut Garnet",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 2, critical: 1, detail: 1 },
    elements: { fire: 4, aether: 1, temporal: 2, light: 1 },
    craftedName: "Garnet",
    craftedTags: [...crystalTags],
    craftedDescription:
      'is strong, beautiful, and rather refined. Garnet is sometimes found embedded into objects, suggesting a "garnet rain" phenomenon yet unobserved.',
  },
  citrine_gem: {
    name: "Cut Citrine",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 2, critical: 0, detail: 2 },
    elements: { electric: 2, chaos: 1, light: 1 },
    craftedName: "Citrine",
    craftedTags: [...crystalTags],
    craftedDescription:
      "is fairly powerful and rather brilliant. Citrine is consistently rare across most worlds and considered a respectable status symbol.",
  },
  bloodstone_gem: {
    name: "Cut Bloodstone",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 2, critical: 2, detail: 0 },
    elements: { bio: 1, spatial: 2, seismic: 2, miasma: 1, ethereal: 2 },
    craftedName: "Bloodstone",
    craftedTags: [...crystalTags],
    craftedDescription:
      "looks pretty cool with the blood splotch red appearance on the otherwise dark coloring. Bloodstone is a stronger stone but is still easy to work with which makes it perfect for precision and power.",
  },
  emerald_gem: {
    name: "Cut Emerald",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 3, critical: 1, detail: 0 },
    elements: { mind: 3, temporal: 1, seismic: 1, ethereal: 1 },
    craftedName: "Emerald",
    craftedTags: [...crystalTags],
    craftedDescription:
      "enjoys great toughness. The emerald can be cut in many ways, making it popular for precision instruments.",
  },
  opal_gem: {
    name: "Cut Opal",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 1, critical: 1, detail: 2 },
    elements: { chaos: 1, ethereal: 1 },
    craftedName: "Opal",
    craftedTags: [...crystalTags],
    craftedDescription:
      "reflects with notable iridescence. It's strong and allows for some precision.",
  },
  quartz_gem: {
    name: "Cut Quartz",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 1, base: 2, critical: 1, detail: 0 },
    elements: { electric: 1, sound: 1, mind: 1, spiritual: 3, seismic: 4 },
    craftedName: "Quartz",
    craftedTags: [...crystalTags],
    craftedDescription:
      "is composed of the quintessential crystal. Quartz may be extremely common, but that is in part to how many applications there are for a reliable and well-rounded crystal.",
  },
  sapphire_gem: {
    name: "Cut Sapphire",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 3, critical: 0, detail: 1 },
    elements: { mind: 1, temporal: 1, spiritual: 1 },
    craftedName: "Sapphire",
    craftedTags: [...crystalTags],
    craftedDescription:
      "has remarkable strength. Folklore on many worlds suggest these gems have mystical or spiritual properties.",
  },
  diamond_gem: {
    name: "Cut Diamond",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 4, critical: 0, detail: 0 },
    elements: { temporal: 1, light: 1, visceral: 4, seismic: 2 },
    craftedName: "Diamond",
    craftedTags: [...crystalTags],
    craftedDescription:
      "is comprised of the hardest naturally occurring material. Clear and smooth, the diamond here was cut flawlessly.",
  },
  sunstone_gem: {
    name: "Cut Sunstone",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 1, critical: 0, detail: 3 },
    elements: {
      electric: 1,
      mind: 2,
      aether: 1,
      temporal: 2,
      light: 2,
      seismic: 2,
    },
    craftedName: "Sunstone",
    craftedTags: [...crystalTags],
    craftedDescription:
      "does not have the same strength that most stones do, but makes up for it with remarkable beauty. Sunstone is in extremely high demand.",
  },
  moonstone_gem: {
    name: "Cut Moonstone",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 2, base: 1, critical: 0, detail: 1 },
    elements: {
      fire: 1,
      cold: 1,
      sound: 1,
      mind: 1,
      dark: 1,
      aether: 1,
      temporal: 2,
      light: 1,
      spiritual: 1,
      nether: 1,
      radiation: 3,
      poison: 1,
      seismic: 4,
    },
    craftedName: "Moonstone",
    craftedTags: [...crystalTags],
    craftedDescription:
      "is light, durable, and rather soft. Moonstone sometimes rains down into the soil when gravity phenomenon collide with various other micro-weather phenomenon at night.",
  },
  onyx_gem: {
    name: "Cut Onyx",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 1, critical: 3, detail: 0 },
    elements: { dark: 1, light: 1, visceral: 1, miasma: 1 },
    craftedName: "Onyx",
    craftedTags: [...crystalTags],
    craftedDescription:
      "demonstrates exceptional precision though it is a stone which tends to be softer than other gems or crystals. Onyx has a stark appearance, and seems to thrive in the hostile atmosphere, making it something of a good luck charm among bandits and explorers.",
  },
  lapisLazuli_gem: {
    name: "Cut Lapis Lazuli",
    description: "",
    tags: [...refinedMaterialTags, ...crystalTags],
    attributes: { actions: 0, base: 1, critical: 2, detail: 0 },
    elements: { mind: 2, aether: 2, temporal: 1 },
    craftedName: "Lapis Lazuli",
    craftedTags: [...crystalTags],
    craftedDescription:
      "is well rounded and especially easy to use as many stones are. It has a remarkable blue color that many worlds try to synthesize, yet it stays in high demand.",
  },
};
