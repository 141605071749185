import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { allItems } from "../../data/Items";
import { functions } from "../../firebase/config";
import { useBasicContext } from "../../hooks/useBasicContext";
import { ItemType } from "../../types/Items";
// import Select from "react-select"

// Styles
import "./Refining.css";

/**TODO:
 * Needs a filterable inventory component
 * Select a resource from that component. (ItemType and ID)
 */

export default function Refining() {
  // const { basic } = useBasicContext();
  type Selection = { type: ItemType; id: number };
  const [selection, setSelection] = useState({
    type: "aluminum_ore",
    id: 3,
  } as Selection);
  const { basic, error } = useBasicContext();

  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as null | string);
  const [material, setMaterial] = useState("" as ItemType | "");
  const [materialIsValid, setMaterialIsValid] = useState(false);
  const [level, setLevel] = useState(null as number | null);
  const [levelIsValid, setLevelIsValid] = useState(false);

  // Turn into state
  const materialSelectionOptions: ItemType[] = [];
  for (const [key, val] of Object.entries(basic.inventory)) {
    if (val.tags.includes("raw"))
      materialSelectionOptions.push(key as ItemType);
  }
  materialSelectionOptions.sort();

  const [levelList, setLevelList] = useState(
    [] as { level: number; amount: number }[]
  );

  const handleRefine = () => {
    setIsPending(true);
    setErrorMessage(null);
    console.log(selection);
    const startTask = httpsCallable(functions, "refineResource");
    const taskRequest = {
      materialType: material,
      materialLevel: level,
    };
    startTask(taskRequest)
      .then((response) => {
        console.log("Success");
        console.log(response);
        setIsPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        setErrorMessage(error.message);
      });
  };

  const materialChange = (e: any) => {
    setMaterialIsValid(false);
    setLevelList([]);
    setLevel(0);
    setLevelIsValid(false);
    const val: ItemType = e.target.value;
    setMaterial(val);
    const mat = basic.inventory[val];
    if (mat !== undefined && mat.tags.includes("raw")) {
      const tempArray: { level: number; amount: number }[] = [];
      mat.instances.forEach((instance) => {
        tempArray.push({ level: instance.level, amount: instance.amount ?? 1 });
      });
      setLevelList(tempArray.sort((a, b) => a.level - b.level));

      setMaterialIsValid(true);
      setSelection((prevState) => {
        return { type: val, id: prevState.id };
      });
    }
  };

  const levelChange = (e: any) => {
    setLevelIsValid(false);
    const val: number = parseInt(e.target.value);
    setLevel(val);

    if (!materialIsValid) return;
    const mat = basic.inventory[selection.type];
    let amount = 0;
    if (
      mat !== undefined &&
      mat.tags.includes("raw") &&
      mat.instances.find((instance) => {
        amount = instance.amount ?? 1;
        return instance.level === val;
      })
    ) {
      setLevelIsValid(true);
      setSelection((prevState) => {
        return { type: prevState.type, id: val };
      });
    }
  };

  return (
    <div className="refining">
      <h1 className="pageName">Refining</h1>

      {materialSelectionOptions.length === 0 && (
        <p className="warning">
          You don't have any items that can be refined. Try heading to the page
          "Resources" to scan and harvest some
        </p>
      )}

      {materialSelectionOptions.length > 0 && (
        <>
          <label>
            <span>Material Selector</span>
            <select onChange={materialChange}>
              <option value={""}>Select material</option>
              {materialSelectionOptions.length > 0 &&
                materialSelectionOptions.map((value) => (
                  <option value={value}>{allItems[value].name}</option>
                ))}
            </select>
          </label>

          {materialIsValid && levelList.length > 0 && (
            <label>
              <span>Level Selector</span>
              <select onChange={levelChange} value={level?.toString()}>
                <option value={""}>Select level</option>
                {levelList.length > 0 &&
                  levelList.map((value) => (
                    <option key={value.level} value={value.level}>
                      Level: {value.level} (x{value.amount})
                    </option>
                  ))}
              </select>
            </label>
          )}

          <label>
            <span>Material ID</span>
            <div className="valid-input">
              <span className="validity">{materialIsValid ? "✅" : "❌"}</span>
              <input
                type="text"
                disabled={isPending}
                onChange={materialChange}
                value={material}
              ></input>
            </div>
          </label>
          <label>
            <span>Item Level</span>
            <div className="valid-input">
              <span className="validity">{levelIsValid ? "✅" : "❌"}</span>

              <input
                type="number"
                disabled={isPending}
                onChange={levelChange}
                value={level ? level : ""}
              ></input>
            </div>
          </label>
        </>
      )}

      {materialIsValid && levelIsValid && (
        <button className="btn" onClick={handleRefine} disabled={isPending}>
          {isPending ? "Loading" : `Refine ${selection.type}.${selection.id}`}
        </button>
      )}
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
}
