import { limit, orderBy, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { GroupMessage, UserMessageData } from "../../types/GroupMessage";
import "./PublicChat.css";
import TimeAgo from "react-timeago";
import { NavLink } from "react-router-dom";
import { renderCustomMarks } from "../../modules/CustomMarks";
import { useAuthContext } from "../../hooks/useAuthContext";

interface GroupMessageDocument extends GroupMessage {
  id: string;
}

type CollectionData = {
  documents: GroupMessageDocument[] | null;
  error: string | null;
};

export default function PublicChat() {
  const { documents: messages, error } = useCollection(
    "chat-rooms/world-chat/messages",
    [orderBy("timestamp", "desc"), limit(25), where("audience", "==", "public")]
  ) as CollectionData;
  const [sendMessage, setSendMessage] = useState("");
  const [sendPending, setSendPending] = useState(false);
  const { user } = useAuthContext();

  const updateMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const message = e.target.value;
    setSendMessage(message);
  };

  const handleSend = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Message filtering and rejection logic
    const message = sendMessage.trim();
    if (message === "") return;

    // Creating the document
    setSendPending(true);
    const userMessageData: UserMessageData = {
      body: message,
      room: "world-chat",
    };
    const sendGroupMessage = httpsCallable(functions, "sendGroupMessage");
    sendGroupMessage(userMessageData)
      .then((response) => {
        setSendPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          console.log("error: " + data.error);
        } else {
          setSendMessage("");
        }
      })
      .catch((error) => {
        console.error(error);
        setSendPending(false);
      });
  };

  return (
    <>
      <h3>Public Chat</h3>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSend}>
        <label>
          <span>Send message</span>
          <div className="send-message">
            <input type="text" onChange={updateMessage} value={sendMessage} />
            <button className="btn" disabled={sendPending}>
              Send
            </button>
          </div>
        </label>
      </form>
      {messages && (
        <ul className="chat">
          {messages.map((message) => {
            const timestamp = new Date(message.timestamp.toMillis()).toString();

            return (
              <li key={message.id} className="chat-message">
                <p className="sender">
                  <NavLink
                    className="profile-link"
                    to={`players/${message.owner}`}
                  >
                    {message.username}
                  </NavLink>

                  <small>
                    {" "}
                    • <TimeAgo date={timestamp} />
                  </small>
                  {/* <span className="timestamp">{timestamp}</span> */}
                </p>
                {/* <p className="uid">{message.owner}</p> */}
                <p className="body">{renderCustomMarks(message.body)}</p>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
