import { useEffect, useState, useRef } from "react";
import { firestore } from "../firebase/config";
import {
  collection,
  query,
  onSnapshot,
  QueryConstraint,
  DocumentData,
} from "firebase/firestore";

// export type Query = {
//   fieldPath: FieldPath | string;
//   opStr: WhereFilterOp;
//   value: unknown;
// };

export type Document = { id: string; [key: string]: unknown };

export const useCollection = (
  _collection: string,
  _query: QueryConstraint[] = []
) => {
  const [documents, setDocuments] = useState(null as Document[] | null);
  const [error, setError] = useState(null as string | null);

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const queryInput = useRef(_query).current;

  useEffect(() => {
    let colRef = collection(firestore, _collection);
    const queryRef = query(colRef, ...queryInput);

    const unsubscribe = onSnapshot(
      queryRef,
      (snapshot) => {
        let results: Document[] = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        // console.log(results);

        // update state
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error.message);
        setError("could not fetch the data");
      }
    );

    // unsubscribe on unmount
    return () => unsubscribe();
  }, [collection, queryInput]);

  return { documents, error };
};
