import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import "./TopBar.css";

export default function TopBar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  return (
    <div className="top-bar">
      <ul>
        <li className="logo">
          <span>Sol-Genesis</span>

          <p className="alpha">[Alpha]</p>
        </li>
        {!user && (
          <>
            <li>
              <Link to="/">Login form</Link>
            </li>
            <li>
              <Link to="register">Register</Link>
            </li>
          </>
        )}
        {user && (
          <li>
            <button className="btn" onClick={logout}>
              Logout
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
