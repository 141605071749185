import { limit, orderBy, where } from "firebase/firestore";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { GovernmentData, JoinGovernmentData } from "../../types/Governments";

import "./Government.css";

interface GovernmentDocument extends GovernmentData {
  id: string;
}

export default function JoinGovernment() {
  const { documents: governments, error } = useCollection("governments", [
    orderBy("createdAt", "desc"),
    limit(10),
  ]) as { documents: GovernmentDocument[] | null; error: string | null };

  const [isPending, setIsPending] = useState(false);
  const [joinError, setJoinError] = useState(null as string | null);

  const [inputGovId, setInputGov] = useState("");

  const handleJoin = (
    e: React.MouseEvent<HTMLButtonElement>,
    govId: string
  ) => {
    setIsPending(true);
    setJoinError(null);
    const joinGov = httpsCallable(
      functions,
      "governmentJoinFromId"
    ) as HttpsCallable<JoinGovernmentData, unknown>;
    joinGov({ id: govId })
      .then((response) => {
        // TODO: .data.error
        console.log(response);
        setIsPending(false);
      })
      .catch((error) => {
        setJoinError(error.message);
        console.error(error);
        setIsPending(false);
      });
  };

  return (
    <>
      <h2>Join Government</h2>
      {error && <p className="error">{error}</p>}
      {joinError && <p className="error">{joinError}</p>}
      <label>
        <span>Government ID:</span>
        <input
          type="text"
          placeholder="Gyc71PBaF5bKlclYFNiO"
          value={inputGovId}
          onChange={(e) => setInputGov(e.target.value)}
          disabled={isPending}
        ></input>
        <button className="btn" onClick={(e) => handleJoin(e, inputGovId)}>
          Join
        </button>
      </label>
      {governments && (
        <ul>
          {governments.map((government) => {
            return (
              <li className="government-join-card" key={government.id}>
                <h3>{government.name}</h3>
                <p>Citizens: {government.citizenCount}</p>
                <p>
                  Influence:{" "}
                  {government.influence + government.citizenInfluence}
                </p>
                <button
                  className="btn"
                  onClick={(e) => handleJoin(e, government.id)}
                  disabled={isPending}
                >
                  Join
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
