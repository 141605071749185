// firebase imports
import { signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export function useLogout() {
  const { dispatch } = useAuthContext();

  function logout() {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT", payload: null });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return { logout };
}
