import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../firebase/config";
import { useClock } from "../hooks/useClock";

export default function PeriodicAutoUpdater() {
  const [lastTime, setLastTime] = useState(Date.now());
  const interval = 300000; // 5 min

  useClock(
    () => {
      const currentMillis = Date.now();
      const currentIntervals = Math.floor(currentMillis / interval);

      const lastMillis = lastTime;
      const lastIntervals = Math.floor(lastMillis / interval);

      // Note: How to make a progress bar for next update occurring:
      // ((currentMillis - lastMillis) / interval)

      // console.log(
      //   "Progress until next update:",
      //   Math.floor(((currentMillis - lastMillis) / interval) * 100 *10) / 10,
      //   "/ 100%"
      // );

      if (currentIntervals - lastIntervals > 0) {
        setLastTime(Date.now());

        const update = httpsCallable(functions, "regenerate");
        update();
        // console.log("Updating");
      }
    },
    1000,
    false
  );

  return <></>;
}
