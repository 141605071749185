import { User } from "firebase/auth";
import { limit, orderBy, Timestamp, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useDocument } from "../../hooks/useDocument";
import { renderCustomMarks } from "../../modules/CustomMarks";
import { Announcement } from "../../types/Announcements";
import { ProfileData } from "../../types/Profile";

import "./Announcements.css";

/** TODO:
 * Create a way to post announcements
 *
 * Create firestore rules for reading and writing announcements
 *  Read if
 *    doc.owner == user.uid
 *    doc.audience == "public"
 *    doc.audience == "government" && doc.govId == user.government
 *    doc.audience == "friends" && user.friends.includes(doc.owner)
 *  Create if
 *    user.staffPermissions.includes("publicAnnouncements")
 *    user.governmentPermissions.includes("postAnnouncements") && (doc.audience == "government" || doc.audience == "government and friends" )
 *    doc.audience == "friends"
 *  Edit if
 *    doc.owner == user.uid
 *    doc.editors.includes(user.uid)
 *    user.permissions.includes("administrator")
 *  Delete if
 *    doc.owner == user.uid
 *
 * Create reactions
 *
 * Create comments
 *    announcements/{uuid}/comments
 */

interface AnnouncementDoc extends Announcement {
  id: string;
}

type AnnouncementsSnapshot = {
  documents: AnnouncementDoc[] | null;
  error: string | null;
};

export default function Announcements() {
  const { documents: publicAnnouncements, error: publicError } = useCollection(
    "announcements",
    [where("audience", "==", "public"), limit(7), orderBy("timestamp", "desc")]
  ) as AnnouncementsSnapshot;

  // New announcement state
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementBody, setAnnouncementBody] = useState("");
  const [isSending, setIsSending] = useState(false);

  // Get user's profile (to check announcement permissions)
  const { user } = useAuthContext() as { user: User };
  const { document: userProfile, error: profileError } = useDocument(
    "players/",
    user.uid
  ) as { document: ProfileData; error: string | null };

  // Submit new announcements
  const submitAnnouncement = (e: any) => {
    e.preventDefault();

    setIsSending(true);

    // console.log("Submitted announcement", announcementTitle, announcementBody);
    if (announcementTitle.trim() === "" || announcementBody.trim() === "") {
      console.error("Failed to send. Either the title or body is empty.");
      return;
    }

    const postAnnouncement = httpsCallable(functions, "postAnnouncement");
    postAnnouncement({
      title: announcementTitle.trim(),
      body: announcementBody.trim(),
    }).finally(() => {
      setIsSending(true);
      setAnnouncementBody("");
      setAnnouncementTitle("");
    });
  };

  return (
    <>
      <h1>Announcements</h1>
      {publicError && <p className="error">{publicError}</p>}
      {profileError && <p className="error">{profileError}</p>}
      {userProfile &&
        userProfile.permissions &&
        (userProfile.permissions.includes("administrator") ||
          userProfile.permissions.includes("publicAnnouncements")) && (
          <div className="create-announcement">
            <form onSubmit={submitAnnouncement}>
              <h2>Create Announcement</h2>
              <input
                type="text"
                placeholder="New Announcement Title"
                onChange={(e: any) => {
                  setAnnouncementTitle(e.target.value);
                }}
                value={announcementTitle}
              ></input>{" "}
              <br />
              <textarea
                placeholder="New announcement body."
                onChange={(e: any) => {
                  setAnnouncementBody(e.target.value);
                }}
                value={announcementBody}
              ></textarea>
              <br />
              <button className="btn" disabled={isSending}>
                Submit
              </button>
              <br />
              <br />
            </form>
          </div>
        )}
      {publicAnnouncements && !publicError && (
        <ul>
          {publicAnnouncements.map((announcement) => {
            const timestamp = new Date(
              announcement.timestamp.toMillis()
            ).toLocaleString();
            return (
              <li key={announcement.id} className="announcement">
                <h2>{announcement.title}</h2>
                <p>{timestamp}</p>
                <p className="announcement-body">
                  {renderCustomMarks(announcement.body)}
                </p>

                <p>{}</p>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
