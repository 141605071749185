import { Link } from "react-router-dom";

export default function DevIndex() {
  return (
    <>
      <h1>Dev Index</h1>
      <ul>
        <li>
          <Link to="/dev-tools/profile-image-uploader">
            Profile Image Uploader
          </Link>
        </li>
        <li>
          <Link to="/dev-tools/tasks">Task testing</Link>
        </li>
      </ul>
    </>
  );
}
