import { NavLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import { TimeFormat } from "../types/Marks";

type Segment = {
  type: "text" | "player" | "government" | "time";
  content: string;
};

function parse(text: string): Segment[] {
  const segments: Segment[] = [];
  const regEx = /\[[A-Za-z0-9\-_. ]+\]{[A-Za-z]+:[A-Za-z0-9]+}/g;
  const matches = text.match(regEx);

  if (matches === null) {
    segments.push({ type: "text", content: text });
    return segments;
  }

  let consume = text;
  matches.forEach((target, index) => {
    const cursor = consume.indexOf(target);
    if (cursor > 0) {
      let textNode: Segment = {
        type: "text",
        content: consume.slice(0, cursor),
      };
      segments.push(textNode);
    }
    consume = consume.slice(cursor + target.length);

    let detailRegEx = /[A-Za-z0-9\-_. ]+/g;
    const details = target.match(detailRegEx);
    if (details === null) return;
    const [name, type, id] = details;

    switch (type.toLowerCase()) {
      case "p":
      case "player":
        segments.push({ type: "player", content: `${name},${id}` });
        break;

      case "g":
      case "gov":
      case "government":
        segments.push({
          type: "government",
          content: `${name},${id}`,
        });
        break;

      case "t":
      case "time":
        segments.push({ type: "time", content: `${name},${id}` });
        break;

      default:
        segments.push({ type: "text", content: target });
        break;
    }
  });

  if (consume.length > 0) {
    segments.push({ type: "text", content: consume });
  }

  return segments;
}

export function renderCustomMarks(text: string): JSX.Element {
  const elements = parse(text).map((segment, index) => {
    const textSegment = <span key={index}>{segment.content}</span>;
    if (segment.type === "text") return textSegment;

    const [name, id] = segment.content.split(",");

    switch (segment.type) {
      case "player":
        return (
          <NavLink
            className={"custom-mark-link"}
            key={index}
            to={`/players/${id}`}
          >
            {name}
          </NavLink>
        );

      case "government":
        return (
          <NavLink
            className={"custom-mark-link"}
            key={index}
            to={`/governments/${id}`}
          >
            {name}
          </NavLink>
        );

      case "time":
        // TODO: Look into Intl.DateTimeFormat
        // Treat as text if time is invalid
        const milliseconds = parseInt(id);
        if (Number.isNaN(milliseconds)) return textSegment;

        switch (name.toLowerCase() as TimeFormat) {
          case "date-time":
            return (
              <span key={index}>{new Date(milliseconds).toLocaleString()}</span>
            );

          case "date":
            return (
              <span key={index}>
                {new Date(milliseconds).toLocaleDateString()}
              </span>
            );

          case "time":
            return (
              <span key={index}>
                {new Date(milliseconds).toLocaleTimeString()}
              </span>
            );

          case "timeago":
          case "relative":
            return (
              <>
                <TimeAgo key={index} date={new Date(milliseconds).toString()} />{" "}
              </>
            );

          default:
            return (
              <TimeAgo key={index} date={new Date(milliseconds).toString()} />
            );
        }

      default:
        return textSegment;
    }
  });

  return <>{elements.map((value) => value)}</>;
}
