import { Navigate, useParams } from "react-router-dom";
import Timeago from "react-timeago";
import { useDocument } from "../../hooks/useDocument";
import { ProfileData } from "../../types/Profile";
import "./UserProfile.css";

interface ProfileDoc extends ProfileData {
  id: string;
}

export default function UserProfile() {
  const { id } = useParams();
  const { document: profileDoc, error: profileError } = useDocument(
    "players/",
    id ?? "invalid-link"
  ) as { document?: ProfileDoc; error?: string };
  return (
    <div className="user-profile">
      {profileError && <p className="error">Failed to find that user</p>}
      {profileDoc && (
        <>
          <h2>{profileDoc.username}</h2>
          {profileDoc.title && (
            <p className="player-title">{profileDoc.title}</p>
          )}
          <p>
            Arrived {convertToDays(profileDoc.createdAt.toMillis())} days ago.
          </p>
        </>
      )}
    </div>
  );
}

function convertToDays(milliseconds: number): number {
  const diff = Date.now() - milliseconds;
  const day = 86400000;
  return Math.floor(diff / day);
}
