import { InventoryObject } from "../../modules/Inventory";
import { useBasicContext } from "../../hooks/useBasicContext";
import ItemList from "../../components/itemList/ItemList";

// styles
import "./Inventory.css";

export default function Inventory() {
  const { basic } = useBasicContext();
  const inventory = basic.inventory as InventoryObject;

  return (
    <>
      <h1>Inventory</h1>
      {inventory && Object.keys(inventory).length === 0 && (
        <p>You do not have any items at the moment!</p>
      )}
      <ul>{inventory && <ItemList items={inventory} />}</ul>
    </>
  );
}
