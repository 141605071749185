import {
  DatabaseItem,
  RawMaterialItemType,
  RefinedMaterialItemType,
} from "../../types/Items";
import { rawAnimalItems } from "./AnimalRaw";
import { rawCrystalItems } from "./CrystalRaw";
import { rawMetalItems } from "./MetalRaw";
import { rawWoodItems } from "./WoodRaw";

export interface RawMaterialDatabase extends DatabaseItem {
  refine_into: RefinedMaterialItemType[];
}

export const rawMaterialItems: {
  [key in RawMaterialItemType]: RawMaterialDatabase;
} = {
  ...rawAnimalItems,
  ...rawCrystalItems,
  ...rawMetalItems,
  ...rawWoodItems,
};
