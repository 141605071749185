import { Timestamp } from "firebase/firestore";
import { InventoryObject } from "../../modules/Inventory";
import { ItemType } from "../../types/Items";
import "./ItemList.css";

export default function ItemList(params: { items: InventoryObject }) {
  const { items } = params;
  return (
    <div>
      {Object.keys(items)
        .sort()
        .map((_value, index) => {
          const value = _value as ItemType;

          let count = 0;
          let totalLevel = 0;

          items[value]?.instances.forEach((itemData) => {
            if (itemData.amount !== undefined) {
              count += itemData.amount;
              totalLevel += itemData.level * itemData.amount;
            } else {
              count += 1;
              totalLevel += itemData.level;
            }
          });

          return (
            <li className="inventory-item" key={value}>
              <div>
                <h2>{items[value]!.name}</h2>
                <p className="item-type">{value} </p>
                <small>
                  Amount: {count.toLocaleString()} <br /> Avg. Level:{" "}
                  {(totalLevel / count).toPrecision(3)}
                </small>
              </div>
              <p>{items[value]!.description}</p>
            </li>
          );
        })}
    </div>
  );
}
