import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useBasicContext } from "../../hooks/useBasicContext";
import { useDocument } from "../../hooks/useDocument";
import "./Dashboard.css";

/** Todo:
 * Update to show all current task details, and empty slots for new tasks
 *
 */

export default function Dashboard() {
  const { user } = useAuthContext();
  const uid = user!.uid; // User is defined in auth routes
  const { document: profile, error } = useDocument("players/", uid);
  const { basic } = useBasicContext();
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as null | string);

  const onTendBar = () => {
    setPending(true);
    const startTask = httpsCallable(functions, "tendBar");
    startTask()
      .then((response) => {
        console.log("Success");
        console.log(response);
        setPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div>
      <h1>Dashboard</h1>
      {profile && (
        <div>
          <p>Welcome to Sol, {profile.username}!</p>
          <br />

          <div className="tend-bar">
            <h2>Earn money</h2>
            <label>
              <span>
                Need a little cash? You could try tending bar... It costs 2520
                energy and takes 8 hours - real time. Your pay is solely based
                on tips, but you'll earn about $50k/hour
              </span>
              <button className="btn" onClick={onTendBar} disabled={pending}>
                I need the money
              </button>
              {errorMessage && <p className="error">{errorMessage}</p>}
            </label>
          </div>
          <div>
            <h2>Tasks</h2>

            <p>
              You currently have {basic.tasks.length} task
              {basic.tasks.length == 1 ? "" : "s"}{" "}
            </p>
            {basic.tasks.length > 0 &&
              basic.tasks.map((taskPlayer) => {
                const taskData = taskPlayer.task;
                const startMillis = taskPlayer.start.toMillis();
                const duration = taskPlayer.duration;
                const endMillis = startMillis + duration;
                return (
                  <div className="task" key={startMillis}>
                    {/* <p className="sub-name">{taskData.profession}</p> */}
                    <p className="finish">
                      Finishes: {new Date(endMillis).toLocaleTimeString()}
                    </p>
                    <p className="reward">
                      Reward: {JSON.stringify(taskPlayer.reward)}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
