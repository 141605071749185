import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { functions } from "../../../firebase/config";
import { useDocument } from "../../../hooks/useDocument";
import { AvatarMaster, AvatarUploadData } from "../../../types/Avatars";

import "./AvatarUpload.css";

interface AvatarMasterDocument extends AvatarMaster {
  id: string;
}

type Gender = "male" | "female" | "other";

export default function AvatarUpload() {
  const [newCollection, setNewCollection] = useState("");
  // const [imagePath, setImagePath] = useState(null as string | null);
  // const [imageFile, setImageFile] = useState(null as File | null);
  const [imageName, setImageName] = useState(null as string | null);
  const [gender, setGender] = useState("female" as Gender);
  const [isPending, setIsPending] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    null as string | ArrayBuffer | null
  );
  const [prompt, setPrompt] = useState("");
  const [collection, setCollection] = useState(
    [] as { value: string; label: string }[]
  );
  const { document: masterDocument, error: masterError } = useDocument(
    "assets/",
    "avatars"
  ) as { document?: AvatarMasterDocument; error?: string };

  const submitNewCollection = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    console.log(newCollection);
    const createAvatarCollection = httpsCallable(
      functions,
      "createAvatarCollection"
    ) as HttpsCallable<{ name: string }, unknown>;
    createAvatarCollection({ name: newCollection })
      .then((data) => {
        console.log(data);
        setNewCollection("");
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPending(false);
      });
  };

  const submitUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);

    if (!selectedImage) return;
    if (!imageName) return;
    let cleanedPrompt = prompt.trim().toLowerCase();
    if (cleanedPrompt.length < 3) return;
    if (collection.length === 0) return;

    const colArray = Object.values(collection).map((option) => option.value);

    const uploadAvatar = httpsCallable(
      functions,
      "uploadAvatar"
    ) as HttpsCallable<AvatarUploadData, unknown>;
    const avatarUploadData: AvatarUploadData = {
      base64ImageString: selectedImage,
      prompt: cleanedPrompt,
      collections: colArray,
      gender: gender,
      fileName: imageName,
    };
    uploadAvatar(avatarUploadData)
      .then((data) => {
        console.log(data);
        setSelectedImage(null);
        setImageName(null);
        setPrompt("");
        setCollection([]);
        setIsPending(false);
        setSelectedImage(null);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsPending(false);
      });
  };

  const imageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPending(true);
    setImageName(null);
    setSelectedImage(null);
    if (!e.target.files) return;
    const file = e.target.files[0];
    //read data from the blob objects(file)
    const reader = new FileReader();
    //reads the binary data and encodes it as base64 data url
    console.log(file);
    reader.readAsDataURL(file);
    //reads it finish with either success or failure
    reader.onloadend = () => {
      //reader.result is the result of the reading in base64 string
      setImageName(file.name);
      setSelectedImage(reader.result);
      console.log(file.name);
      setIsPending(false);
      // console.log(reader.result);
    };
  };

  return (
    <div>
      <h1>Profile Image Uploader</h1>
      <form className="basic-tile" onSubmit={submitNewCollection}>
        <h2>Create New Collection</h2>
        <label>
          <span>Collection name</span>
          <input
            placeholder="collection"
            value={newCollection}
            onChange={(e) => setNewCollection(e.target.value)}
          />
          <button disabled={isPending} className="btn secondary">
            Add Collection
          </button>
        </label>
      </form>
      <br />
      {masterDocument && (
        <form className="basic-tile" onSubmit={submitUpload}>
          <h2>Upload Profile Image</h2>
          <label>
            <span>Select Image</span>
            <input
              accept="image/*"
              type="file"
              onChange={imageSelect}
              // value={imageName ?? ""}
            />
          </label>
          <label>
            <span>Prompt</span>
            <input
              type="text"
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
            />
          </label>
          <span>Gender</span>
          <label className="radio-label">
            <input
              type="radio"
              value="male"
              name="gender"
              onChange={(e) => setGender(e.target.value as Gender)}
              checked={gender == "male"}
            />
            <span>Male</span>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="female"
              name="gender"
              onChange={(e) => setGender(e.target.value as Gender)}
              checked={gender == "female"}
            />
            <span>Female</span>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="other"
              name="gender"
              onChange={(e) => setGender(e.target.value as Gender)}
              checked={gender == "other"}
            />
            <span>Other</span>
          </label>
          <label>
            <span>Collections</span>
            <MultiSelect
              className="multi-select"
              options={Object.keys(masterDocument.collections).map(
                (collection) => {
                  return {
                    value: collection,
                    label: `${collection} (${masterDocument.collections[collection]})`,
                  };
                }
              )}
              value={collection}
              onChange={setCollection}
              labelledBy="Select"
            />
          </label>
          <button disabled={isPending} className="btn">
            Submit
          </button>
        </form>
      )}
    </div>
  );
}
