import { Timestamp, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { Plot, plotCost } from "../../data/Plots";
import { functions } from "../../firebase/config";
import { useBasicContext } from "../../hooks/useBasicContext";
import { useCollection } from "../../hooks/useCollection";

interface PlotDocument extends Plot {
  id: string;
}

type CollectionData = {
  documents: PlotDocument[] | null;
  error: string | null;
};

export default function Homestead() {
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as null | string);
  const { basic } = useBasicContext();

  const { documents: plots, error } = useCollection("plots", [
    where("owner", "==", basic.owner),
  ]) as CollectionData;

  const handleBuyPlot = () => {
    setIsPending(true);
    setErrorMessage(null);
    const buyPlot = httpsCallable(functions, "buyPlot");
    buyPlot()
      .then((response) => {
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
        setIsPending(false);
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      <h1 className="page-name">Homestead</h1>

      <label>
        <span>
          Current cost for a plot: {plotCost(Timestamp.now()).toLocaleString()}$
        </span>
        <button className="btn" disabled={isPending} onClick={handleBuyPlot}>
          Buy Plot
        </button>
        {errorMessage && <p className="error">{errorMessage}</p>}
      </label>

      {plots && <p>You currently own {plots.length} plots</p>}
      {plots && (
        <ul className="BasicList">
          {plots.map((plot) => {
            return (
              <li key={plot.id}>
                <p>level: {plot.level}</p>
                <p>in development? {plot.buildData.inDevelopment.toString()}</p>
                <p>building: {plot.buildData.building ?? "None"}</p>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
