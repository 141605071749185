import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../../firebase/config";
import { SendMoneyData } from "../../types/SendMoney";
import "./SendMoney.css";

export default function SendMoney() {
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(null as string | null);
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPending(true);
    setError(null);

    const sendMoney = httpsCallable(
      functions,
      "sendMoneyToName"
    ) as HttpsCallable<SendMoneyData, unknown>;

    const sendMoneyData: SendMoneyData = {
      amount: Math.floor(amount),
      name: recipient.toLowerCase(),
    };

    sendMoney(sendMoneyData)
      .then((data: any) => {
        const dataError = data.data.error;
        if (dataError !== undefined) {
          setError(dataError);
          throw new Error(dataError);
        }
        setIsPending(false);
        setAmount(0);
        setRecipient("");
      })
      .catch((responseError) => {
        console.error(responseError);
        setError(responseError.message);
        setIsPending(false);
      });
  };

  return (
    <>
      <h1>Send Money</h1>
      <form onSubmit={handleSubmit}>
        {error && <p className="error">{error}</p>}
        <label>
          <span>Recipient's Username:</span>
          <input
            type="text"
            placeholder="Username"
            value={recipient}
            onChange={(e) => {
              setRecipient(e.target.value);
            }}
          ></input>
        </label>
        <label>
          <span>Amount:</span>
          <input
            type="number"
            placeholder="0"
            value={amount}
            onChange={(e) => {
              setAmount(parseInt(e.target.value));
            }}
          ></input>
        </label>
        <button className="btn" disabled={isPending}>
          Send
        </button>
      </form>
    </>
  );
}
