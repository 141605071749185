import React from "react";

const isInFocus = () =>
  document === undefined || document.visibilityState === undefined
    ? true
    : document.visibilityState === "visible";

export function useClock(
  func: Function,
  delay: number,
  skipUnfocused = false
): void {
  const funcRef = React.useRef<Function>();
  React.useEffect(() => {
    funcRef.current = func;
  }, [func]);

  React.useEffect(() => {
    const exec = () => {
      if (!funcRef.current || (skipUnfocused && !isInFocus())) return;
      funcRef.current();
    };
    const i = setInterval(exec, delay);
    return () => clearInterval(i);
  }, [delay]);
}
