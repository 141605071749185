import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

// firebase imports
import { auth, functions } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

export function useSignup() {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  function signup(username: string, email: string, password: string) {
    setError(null);
    const register = httpsCallable(functions, "register");
    register({ username, email, password })
      .then((response) => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  return { error, signup };
}
