import { Attributes } from "../../types/Attributes";
import { CraftingShapeType, DatabaseItem, ItemTag } from "../../types/Items";

const templateTags: ItemTag[] = ["crafted"];

export type Category = "Melee" | "Ranged" | "Magic" | "Utility";
export type Subcategory =
  | "Stab"
  | "Crush"
  | "Slash"
  | "Throw"
  | "Shoot"
  | "Drone"
  | "Implement"
  | "Evoke"
  | "Book"
  | "Support"
  | "Tool";
export type Part =
  | "Blade"
  | "Spiked Ball"
  | "Body"
  | "Structure"
  | "Tip"
  | "Composition"
  | "Frame"
  | "Chassis"
  | "Receiver"
  | "Center"
  | "Core"
  | "Handle"
  | "Cover"
  | "Attenuator"
  | "Replicator"
  | "Emitter"
  | "Field Discriminator"
  | "Container"
  | "Transceiver"
  | "Resequencer"
  | "Regulator"
  | "Case"
  | "Head";

// Crafting Item Shapes
export interface CraftingShapeDatabase extends DatabaseItem {
  attributes: Attributes;
  category: Category;
  subcategory: Subcategory;
  part: Part;
  craftedDescription: string;
  craftedTags: ItemTag[];
} // Exported for testing

export const craftingShapeTotalAttributes = 3; // Exported for testing
export const craftingShapeItems: {
  [key in CraftingShapeType]: CraftingShapeDatabase;
} = {
  dagger_template: {
    name: "Dagger",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Stab",
    part: "Blade",
    attributes: { actions: 3, base: 0, critical: 0, detail: 0 },
    craftedDescription:
      "prized for its versatility, light weight, and speed that makes up for its lack of reach and power.",
    craftedTags: [...templateTags],
  },
  greatsword_template: {
    name: "Greatsword",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Stab",
    part: "Blade",
    attributes: { actions: 2, base: 0, critical: 0, detail: 1 },
    craftedDescription:
      "considered to be extremely popular thanks to its increased reach and power while still being quick among melee weapons.",
    craftedTags: [...templateTags],
  },
  rapier_template: {
    name: "Rapier",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Stab",
    part: "Blade",
    attributes: { actions: 2, base: 0, critical: 1, detail: 0 },
    craftedDescription:
      "that is not only fast but quite precise which compliments its speed with improved critical.",
    craftedTags: [...templateTags],
  },
  morningstar_template: {
    name: "Morningstar",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Crush",
    part: "Spiked Ball",
    attributes: { actions: 1, base: 2, critical: 0, detail: 0 },
    craftedDescription: "",
    craftedTags: [...templateTags],
  },
  katana_template: {
    name: "Katana",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Slash",
    part: "Blade",
    attributes: { actions: 1, base: 0, critical: 2, detail: 0 },
    craftedDescription:
      "unique for its remarkable precision. Katanas are considered fast and precise, making them deadly slashing weapons.",
    craftedTags: [...templateTags],
  },
  shotgun_template: {
    name: "Shotgun",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Crush",
    part: "Body",
    attributes: { actions: 1, base: 1, critical: 0, detail: 1 },
    craftedDescription:
      "with pump action capability. Shotguns are deadly up close and deter direct approach.",
    craftedTags: [...templateTags],
  },
  scimitar_template: {
    name: "Scimitar",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Slash",
    part: "Blade",
    attributes: { actions: 1, base: 1, critical: 1, detail: 0 },
    craftedDescription:
      "that has a curved form to provide devastating slash attacks with deadly precision.",
    craftedTags: [...templateTags],
  },
  buckler_template: {
    name: "Buckler",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Melee",
    subcategory: "Crush",
    part: "Body",
    attributes: { actions: 1, base: 0, critical: 0, detail: 2 },
    craftedDescription:
      "that sacrifices its offensive capabilities for increased protection at close range.",
    craftedTags: [...templateTags],
  },
  shuriken_template: {
    name: "Shuriken",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Throw",
    part: "Structure",
    attributes: { actions: 1, base: 0, critical: 1, detail: 1 },
    craftedDescription:
      "that is extremely fast. Shuriken can be thrown with some precision at a speed other ranged weaponry can't surpass.",
    craftedTags: [...templateTags],
  },
  longbow_template: {
    name: "Longbow",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Shoot",
    part: "Body",
    attributes: { actions: 0, base: 1, critical: 1, detail: 1 },
    craftedDescription:
      "famously powerful for its penetrating force and reach. Similar weapons have been a menace of battlefields through all time on many worlds.",
    craftedTags: [...templateTags],
  },
  javelin_template: {
    name: "Javelin",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Throw",
    part: "Tip",
    attributes: { actions: 0, base: 0, critical: 3, detail: 0 },
    craftedDescription:
      "with unmatched precision among all weapons thanks to its medium range and stability even through this unpredictable atmosphere. Critical hits dealt by skilled javelin throwers usually end fights before they begin.",
    craftedTags: [...templateTags],
  },
  trumbash_template: {
    name: "Trumbash",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Throw",
    part: "Composition",
    attributes: { actions: 1, base: 1, critical: 1, detail: 0 },
    craftedDescription:
      "with a unique design created to increase the likelihood of penetrating armor. It can be thrown relatively quickly and its unique shape makes it difficult to predict or avoid.",
    craftedTags: [...templateTags],
  },
  crossbow_template: {
    name: "Crossbow",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Shoot",
    part: "Frame",
    attributes: { actions: 0, base: 1, critical: 2, detail: 0 },
    craftedDescription:
      "that is viciously precise and powerful. Crossbows are bulky but make up for that by punching through armor with ease.",
    craftedTags: [...templateTags],
  },
  aerialDrone_template: {
    name: "Aerial Drone",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Drone",
    part: "Chassis",
    attributes: { actions: 0, base: 0, critical: 1, detail: 2 },
    craftedDescription:
      "designed for infantry support. Unfortunately, the dynamic elements and unpredictable atmosphere demands constant manual calibration with a controller, making these devices more like novel diversions rather than letting them be employed how they typically are.",
    craftedTags: [...templateTags],
  },
  rover_template: {
    name: "Rover",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Drone",
    part: "Chassis",
    attributes: { actions: 0, base: 2, critical: 1, detail: 0 },
    craftedDescription:
      "that engages and grapples aggressors in order to subdue them. These types of drones are controller operated.",
    craftedTags: [...templateTags],
  },
  rifle_template: {
    name: "Rifle",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Ranged",
    subcategory: "Shoot",
    part: "Receiver",
    attributes: { actions: 0, base: 0, critical: 2, detail: 1 },
    craftedDescription:
      "using an advanced rangefinder to aid in precision. Rifles take a very long time to find a shot through the various micro-weather events found on the planet but can be done so at good range.",
    craftedTags: [...templateTags],
  },
  orb_template: {
    name: "Orb",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Implement",
    part: "Center",
    attributes: { actions: 2, base: 1, critical: 0, detail: 0 },
    craftedDescription:
      "used as an implement to scry or channel magical energies as fast as possible.",
    craftedTags: [...templateTags],
  },
  staff_template: {
    name: "Staff",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Evoke",
    part: "Composition",
    attributes: { actions: 0, base: 3, critical: 0, detail: 0 },
    craftedDescription:
      "that evokes magic with more power than any other device, the staff provides raw power no weapon can hope to exceed.",
    craftedTags: [...templateTags],
  },
  wand_template: {
    name: "Wand",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Evoke",
    part: "Core",
    attributes: { actions: 1, base: 1, critical: 0, detail: 1 },
    craftedDescription:
      "which evokes magic quicker than most other arcane devices.",
    craftedTags: [...templateTags],
  },
  athame_template: {
    name: "Athame",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Evoke",
    part: "Handle",
    attributes: { actions: 0, base: 2, critical: 1, detail: 0 },
    craftedDescription:
      "that can evoke powerful magic. Its design controls and guides magic with decent precision.",
    craftedTags: [...templateTags],
  },
  tome_template: {
    name: "Tome",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Book",
    part: "Cover",
    attributes: { actions: 0, base: 1, critical: 2, detail: 0 },
    craftedDescription:
      "unmatched in precision. A complex book that takes time to use but with greater power and critical than other magic devices.",
    craftedTags: [...templateTags],
  },
  grenadeLauncher_template: {
    name: "Grenade Launcher",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Implement",
    part: "Attenuator",
    attributes: { actions: 0, base: 2, critical: 0, detail: 1 },
    craftedDescription:
      "which generates and launches devastating payloads of magical energy.",
    craftedTags: [...templateTags],
  },
  totem_template: {
    name: "Totem",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Implement",
    part: "Replicator",
    attributes: { actions: 1, base: 2, critical: 0, detail: 0 },
    craftedDescription:
      "that, once deployed, can be fed with magical energy rapidly and replicate the magic with greater potency.",
    craftedTags: [...templateTags],
  },
  aura_template: {
    name: "Aura",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Magic",
    subcategory: "Implement",
    part: "Emitter",
    attributes: { actions: 0, base: 1, critical: 0, detail: 2 },
    craftedDescription:
      "operating through reimagined environmental technology and is able to produce closed environments and powerful force fields though it does take some time to configure thanks to the volatile atmosphere.",
    craftedTags: [...templateTags],
  },
  pavise_template: {
    name: "Pavise",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Support",
    part: "Frame",
    attributes: { actions: 0, base: 0, critical: 0, detail: 3 },
    craftedDescription:
      "specifically made for defense. This large shield is able to cover the user and another person from incoming attacks and is able to withstand almost anything.",
    craftedTags: [...templateTags],
  },
  particleTool_template: {
    name: "Particle Tool",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Tool",
    part: "Field Discriminator",
    attributes: { actions: 2, base: 0, critical: 0, detail: 1 },
    craftedDescription:
      "commonly carried for interstellar expeditions. This tool is capable of manipulating matter and directing various energy types at a target. On this planet, devices such as this have to be constantly recalibrated and users must manually account for field variations due to the atmospheric phenomena.",
    craftedTags: [...templateTags],
  },
  theriac_template: {
    name: "Theriac",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Support",
    part: "Container",
    attributes: { actions: 0, base: 0, critical: 1, detail: 2 },
    craftedDescription:
      "used to influence the condition of others through a combination of natural materials automatically gathered and blended in order to bring out the elements within them.",
    craftedTags: [...templateTags],
  },
  pylon_template: {
    name: "Pylon",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Support",
    part: "Transceiver",
    attributes: { actions: 1, base: 0, critical: 2, detail: 0 },
    craftedDescription:
      "that receives and broadcasts a wide range of energy and information both technological and magical.",
    craftedTags: [...templateTags],
  },
  toolReplicator_template: {
    name: "Tool Replicator",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Tool",
    part: "Resequencer",
    attributes: { actions: 1, base: 0, critical: 1, detail: 1 },
    craftedDescription:
      "that converts stored energy into matter. It is limited to producing simple tools such as lock picks, nails, hammers, and cables.",
    craftedTags: [...templateTags],
  },
  generator_template: {
    name: "Generator",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Tool",
    part: "Regulator",
    attributes: { actions: 0, base: 2, critical: 1, detail: 0 },
    craftedDescription:
      "prototype attempting to convert various god energy from the micro-weather phenomenon into a viable energy source rivaling that of current anti-matter reactors.",
    craftedTags: [...templateTags],
  },
  traumaKit_template: {
    name: "Trauma Kit",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Support",
    part: "Case",
    attributes: { actions: 0, base: 1, critical: 0, detail: 2 },
    craftedDescription:
      "for providing critical aid to allies with serious injuries.",
    craftedTags: [...templateTags],
  },
  axe_template: {
    name: "Axe",
    description: "NeedsDescription",
    tags: templateTags,
    category: "Utility",
    subcategory: "Tool",
    part: "Head",
    attributes: { actions: 1, base: 1, critical: 0, detail: 1 },
    craftedDescription:
      "that is especially useful for general labor. Particularly work that involves breaking things down such as mining or construction.",
    craftedTags: [...templateTags],
  },
};
