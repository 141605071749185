import { useNavigate } from "react-router-dom";
import { useBasicContext } from "../../hooks/useBasicContext";
import { useProfileContext } from "../../hooks/useProfileContext";
import { hasPermission } from "../../modules/HasPermission";
import { Permission } from "../../types/Permission";
import { ProfileData } from "../../types/Profile";

import "./DevRoute.css";

export default function DevRoute(props: {
  element: JSX.Element;
  permission?: Permission;
}) {
  const { element, permission } = props;
  const { basic, error } = useBasicContext();
  const navigate = useNavigate();

  return (
    <>
      {basic && hasPermission(basic, permission) && element}
      {basic && !hasPermission(basic, permission) && (
        <>
          <h1>Not allowed</h1>
          <p>
            A man stands in front of the door. You notice a camera watching you
            as you walk towards the entrance. The door has a large sign with big
            print in all caps. "Staff only," it reads. You decide it's best to
            keep moving.
          </p>
          <br />
          <p className="link" onClick={() => navigate(-1)}>
            Go back
          </p>
        </>
      )}
    </>
  );
}
