import { ItemTag, RefinedAnimalItemType } from "../../types/Items";
import { RefinedMaterialDatabase } from "./RefinedMaterials";

const refinedAnimalTags: ItemTag[] = ["refined"];

const boneTags: ItemTag[] = ["bone", "animal"];
const leatherTags: ItemTag[] = ["leather", "animal"];

export const refinedAnimalItems: {
  [key in RefinedAnimalItemType]: RefinedMaterialDatabase;
} = {
  rodent_hide: {
    name: "Rodent Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 0, critical: 0, detail: 4 },
    elements: { cold: 1, miasma: 3, ethereal: 1 },
    craftedName: "Rodent",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is extremely sought after, boasting some of the agility the animals tend to have. Rodents tend to have fine hair and soft skin which make them particularly desirable.",
  },
  deer_hide: {
    name: "Deer Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 1, base: 0, critical: 2, detail: 1 },
    elements: { cold: 1, mind: 1, aether: 1, seismic: 1 },
    craftedName: "Deer",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is light and great to work with, these skins are extremely practical. Deer animals tend to roam or meander great distances in small groups.",
  },
  shark_hide: {
    name: "Shark Integument",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 1, critical: 0, detail: 3 },
    elements: { electric: 3, sound: 1, dark: 1, chaos: 1 },
    craftedName: "Shark",
    craftedTags: [...leatherTags],
    craftedDescription:
      "enjoys a thick cartilaginous composition that is remarkably desirable and considered a status symbol on many worlds.",
  },
  cattle_hide: {
    name: "Cattle Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 3, critical: 0, detail: 1 },
    elements: {
      cold: 2,
      mind: 1,
      bio: 1,
      light: 1,
      visceral: 2,
      spiritual: 1,
      seismic: 1,
      miasma: 2,
    },
    craftedName: "Cattle",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is tough and thick. Cattle are any number of grazing species. These creatures survive thanks to being large and being in large herds. These traits trend towards easy domestication. The hides are commonly seen as both an allegory to the spiritual connection of life and nature as well as a status symbol to various cultures.",
  },
  mollusc_hide: {
    name: "Mollusc Shell",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 1, base: 1, critical: 0, detail: 2 },
    elements: { sound: 1, mind: 1, seismic: 2, miasma: 1 },
    craftedName: "Mollusc",
    craftedTags: [...leatherTags],
    craftedDescription:
      "offers the resilience all cartilaginous hides are valued for while also being well-rounded as a lighter, if not alien, option.",
  },
  serpent_hide: {
    name: "Serpent Scale",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 0, critical: 3, detail: 1 },
    elements: { dark: 1, corrosive: 1, nether: 1, poison: 1 },
    craftedName: "Serpent",
    craftedTags: [...leatherTags],
    craftedDescription:
      "has unrivaled flexibility as the scales glide and pitch with flawless precision. Serpents tend to be ambush predators and their hides are commonly associated as culture symbols for cunning, patience, and allure.",
  },
  fish_hide: {
    name: "Fish Scale",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 3, base: 0, critical: 0, detail: 1 },
    elements: { radiation: 1, poison: 1 },
    craftedName: "Fish",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is very light thanks to these creatures requiring a thin skin to absorb moisture whenever the variable atmospheric conditions allow.",
  },
  lizard_hide: {
    name: "Lizard Scale",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 2, base: 0, critical: 0, detail: 2 },
    elements: { dark: 1, bio: 1, chaos: 1 },
    craftedName: "Lizard",
    craftedTags: [...leatherTags],
    craftedDescription:
      "comes from what is generally a more primitive skin. Lizard type creatures with thin hides tend to appear rather early in most fossil records of extant species on nearly every world they have been recorded on. These skins are very light and usually excel at heat and oxygen transfer which make them valued commodities.",
  },
  horse_hide: {
    name: "Horse Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 1, base: 2, critical: 0, detail: 1 },
    elements: {
      fire: 1,
      cold: 1,
      electric: 1,
      aether: 1,
      spatial: 1,
      ethereal: 1,
    },
    craftedName: "Horse",
    craftedTags: [...leatherTags],
    craftedDescription:
      "has a reassuring quality to it thanks to its strength. Horse species are extremely intelligent on most worlds. While not as tough or as easy to domesticate as cattle nor as transient and supple as deer, horse species tend to be strong and fast, providing options to survive predators of almost any ecosystem. Their hides have the same traits, making them extremely popular for both building and crafting.",
  },
  ape_hide: {
    name: "Ape Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 0, critical: 1, detail: 3 },
    elements: { mind: 5, dark: 2, chaos: 3, nether: 1 },
    craftedName: "Ape",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is elastic and fatty, almost making it serpent-like in how flexible it is while still being quite supple. There is a strange fascination for ape hides that keeps the market value well above the value of most commercial or industrial applications.",
  },
  cat_hide: {
    name: "Cat Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 0, critical: 2, detail: 2 },
    elements: { fire: 1, cold: 1, sound: 1, bio: 1, visceral: 1, spiritual: 1 },
    craftedName: "Cat",
    craftedTags: [...leatherTags],
    craftedDescription:
      "is beautiful and flexible. The elegance and softness of this hide makes it no wonder creatures of this type tend to be apex predators in many ecosystems of many worlds.",
  },
  wolf_hide: {
    name: "Wolf Pelt",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 1, critical: 1, detail: 2 },
    elements: { cold: 4, spiritual: 1, seismic: 1 },
    craftedName: "Wolf",
    craftedTags: [...leatherTags],
    craftedDescription:
      "has a fluffy, pleasant quality to it. The hides come from any one of pack hunting predators. These creatures usually have thick fur making it popular, and to wear it demands respect from all.",
  },
  raptor_hide: {
    name: "Raptor Integument",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 2, base: 0, critical: 1, detail: 1 },
    elements: { sound: 1, temporal: 1, visceral: 1, spiritual: 1 },
    craftedName: "Raptor",
    craftedTags: [...leatherTags],
    craftedDescription:
      "still possesses the plumage from the original creature. The material is plenty light and has the same finesse these predators tend to have.",
  },
  bug_hide: {
    name: "Bug Integument",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 1, critical: 2, detail: 1 },
    elements: {
      electric: 1,
      dark: 1,
      bio: 3,
      corrosive: 3,
      poison: 3,
      miasma: 1,
    },
    craftedName: "Bug",
    craftedTags: [...leatherTags],
    craftedDescription:
      "isn't actually leather, but a meticulously folded and carved carapace which makes it strong if not somewhat brittle.",
  },
  fowl_hide: {
    name: "Fowl Integument",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 1, base: 0, critical: 0, detail: 3 },
    elements: { chaos: 2, nether: 1 },
    craftedName: "Fowl",
    craftedTags: [...leatherTags],
    craftedDescription:
      "has a sleek sheen from its feathers. The material seems to have down underneath and a unique softness to it.",
  },
  amphibian_hide: {
    name: "Amphibian Integument",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 0, base: 2, critical: 1, detail: 1 },
    elements: {
      fire: 1,
      sound: 1,
      mind: 1,
      dark: 1,
      bio: 1,
      chaos: 1,
      corrosive: 1,
      light: 1,
      visceral: 1,
      spiritual: 1,
      nether: 1,
      radiation: 1,
      poison: 8,
      seismic: 1,
      miasma: 1,
      ethereal: 1,
    },
    craftedName: "Amphibian",
    craftedTags: [...leatherTags],
    craftedDescription:
      "has a springy surface beguiling its toughness. Amphibian hides are durable thanks to their adaptive traits allowing them to cope with multiple environments.",
  },
  // Bone start
  rodent_bone: {
    name: "Rodent Bone",
    description: "",
    tags: [...refinedAnimalTags, ...leatherTags],
    attributes: { actions: 2, base: 0, critical: 2, detail: 0 },
    elements: { cold: 1, bio: 1, chaos: 3, nether: 1, seismic: 2, miasma: 4 },
    craftedName: "Rodent",
    craftedTags: [...boneTags],
    craftedDescription:
      "is light and easy to use. Rodent bones are a humble material that serves many purposes.",
  },
  deer_bone: {
    name: "Deer Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 1, base: 1, critical: 2, detail: 0 },
    elements: { cold: 1, temporal: 1, light: 1, nether: 3, ethereal: 3 },
    craftedName: "Deer",
    craftedTags: [...boneTags],
    craftedDescription:
      "makes for good precision. It is also somewhat light and tough in equal measure making it rather unique.",
  },
  shark_bone: {
    name: "Shark Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 1, base: 0, critical: 1, detail: 2 },
    elements: { sound: 1, dark: 2, chaos: 4, visceral: 4, nether: 1 },
    craftedName: "Shark",
    craftedTags: [...boneTags],
    craftedDescription:
      "is quite soft since it isn't a true bone. The material is fairly light and considered something of an exotic good.",
  },
  cattle_bone: {
    name: "Cattle Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 0, critical: 0, detail: 0 },
    elements: { fire: 3, electric: 1, visceral: 3, nether: 5, spatial: 1 },
    craftedName: "Cattle",
    craftedTags: [...boneTags],
    craftedDescription:
      "is made of one of the most precise materials in the known worlds. Thanks to the volatile environment of this world, herding and grazing animals tend to be very strong in order to endure the extreme weather phenomenon. Their bones are a perfect material to work with and can be precise nearly to the atomic level.",
  },
  mollusc_bone: {
    name: "Mollusc Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 2, base: 0, critical: 1, detail: 1 },
    elements: {
      electric: 1,
      mind: 1,
      dark: 1,
      aether: 3,
      bio: 1,
      nether: 3,
      poison: 2,
      ethereal: 1,
    },
    craftedName: "Mollusc",
    craftedTags: [...boneTags],
    craftedDescription:
      "isn't truly bone, but actually a very lightweight cartilaginous structure that can be sculpted with some precision and elegance.",
  },
  serpent_bone: {
    name: "Serpent Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 0, critical: 3, detail: 1 },
    elements: {
      sound: 1,
      dark: 4,
      visceral: 1,
      spiritual: 1,
      nether: 4,
      poison: 10,
      seismic: 1,
      miasma: 1,
      ethereal: 1,
    },
    craftedName: "Serpent",
    craftedTags: [...boneTags],
    craftedDescription: "flexes and bends with remarkable ease.",
  },
  fish_bone: {
    name: "Fish Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 3, base: 0, critical: 1, detail: 0 },
    elements: { temporal: 1, visceral: 3, nether: 1, poison: 1 },
    craftedName: "Fish",
    craftedTags: [...boneTags],
    craftedDescription:
      "nearly floats thanks to how aerated the bones it's composed of are.",
  },
  lizard_bone: {
    name: "Lizard Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 0, critical: 1, detail: 3 },
    elements: { bio: 1, corrosive: 1, nether: 1, seismic: 3 },
    craftedName: "Lizard",
    craftedTags: [...boneTags],
    craftedDescription:
      "benefits from remarkable softness though it isn't as precise as other bones. Worlds without lizards sometimes believe the marrow of the bones have healing properties which keeps them in high demand.",
  },
  horse_bone: {
    name: "Horse Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 1, critical: 3, detail: 0 },
    elements: { fire: 1, electric: 3, mind: 1, nether: 1 },
    craftedName: "Horse",
    craftedTags: [...boneTags],
    craftedDescription:
      "boasts good toughness considering it is made of bone. The ductile nature of the bone is attributed to adaptations from the volatile environment, letting these mobile creatures retain more collagen and move through most atmospheric phenomena to escape threats.",
  },
  ape_bone: {
    name: "Ape Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 0, critical: 2, detail: 2 },
    elements: {
      fire: 4,
      cold: 1,
      sound: 2,
      mind: 8,
      dark: 3,
      bio: 2,
      chaos: 8,
      visceral: 3,
      nether: 8,
      miasma: 1,
    },
    craftedName: "Ape",
    craftedTags: [...boneTags],
    craftedDescription:
      "shows remarkable detail. The bone material can flex fairly well given their shape and structure.",
  },
  cat_bone: {
    name: "Cat Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 1, base: 0, critical: 3, detail: 0 },
    elements: {
      cold: 1,
      mind: 1,
      chaos: 1,
      light: 1,
      visceral: 1,
      spiritual: 1,
      miasma: 4,
      ethereal: 1,
    },
    craftedName: "Cat",
    craftedTags: [...boneTags],
    craftedDescription:
      "with above average lightness for bone contributes to its unique precision.",
  },
  wolf_bone: {
    name: "Wolf Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 1, base: 0, critical: 2, detail: 1 },
    elements: {
      mind: 1,
      dark: 1,
      aether: 1,
      light: 1,
      spiritual: 2,
      seismic: 1,
      miasma: 1,
    },
    craftedName: "Wolf",
    craftedTags: [...boneTags],
    craftedDescription:
      "not only enjoys a light weight from this material, it is considered somewhat exotic.",
  },
  raptor_bone: {
    name: "Raptor Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 0, base: 2, critical: 1, detail: 1 },
    elements: {
      sound: 4,
      dark: 1,
      light: 1,
      spiritual: 1,
      nether: 1,
      ethereal: 1,
    },
    craftedName: "Raptor",
    craftedTags: [...boneTags],
    craftedDescription:
      "is remarkably strong for true bone. The aerated marrow in raptor bones allows these creatures to be durable on their thin frames which improves maneuverability.",
  },
  bug_bone: {
    name: "Bug Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 1, base: 2, critical: 0, detail: 1 },
    elements: {
      temporal: 1,
      corrosive: 1,
      nether: 3,
      radiation: 3,
      poison: 3,
      miasma: 1,
    },
    craftedName: "Bug",
    craftedTags: [...boneTags],
    craftedDescription:
      "is actually carefully aligned tubules of chitin. The material is stronger and more resilient than normal bone.",
  },
  fowl_bone: {
    name: "Fowl Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 3, base: 0, critical: 0, detail: 1 },
    elements: { visceral: 1, nether: 3 },
    craftedName: "Fowl",
    craftedTags: [...boneTags],
    craftedDescription:
      "seems hollow and weighs similarly. The bone structure is extremely light and fast, but brittle as a consequence.",
  },
  amphibian_bone: {
    name: "Amphibian Bone",
    description: "",
    tags: [...refinedAnimalTags, ...boneTags],
    attributes: { actions: 2, base: 1, critical: 0, detail: 1 },
    elements: { dark: 1, corrosive: 1, spiritual: 4, nether: 1, radiation: 1 },
    craftedName: "Amphibian",
    craftedTags: [...boneTags],
    craftedDescription:
      "is smooth and sleek. The material is extremely adaptive and light, just like the creatures it comes from.",
  },
};
