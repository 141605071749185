import TaskTestButton from "./TaskTestButton";

export default function TaskTest() {
  return (
    <>
      <h1>Task test</h1>
      <TaskTestButton
        task="harvestScan"
        selection={{
          scanType: "scan_wood",
          scanId:
            "1554a0c7c6d470d2a05d751588c01479f68b9ccf0acc9da193cc630b40ae914d",
        }}
      >
        Harvest Scan
      </TaskTestButton>
    </>
  );
}
