import { ItemTag, RawCrystalItemType } from "../../types/Items";
import { RawMaterialDatabase } from "./RawMaterials";

const roughGemstoneDescription =
  "An uncut gemstone. This item can be refined into a crafting material.";

// Shape of Raw Materials
const rawMaterialTags: ItemTag[] = ["raw"];

export const rawCrystalItems: {
  [key in RawCrystalItemType]: RawMaterialDatabase;
} = {
  garnet_chunk: {
    name: "Rough Garnet",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["garnet_gem"],
  },
  citrine_chunk: {
    name: "Rough Citrine",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["citrine_gem"],
  },
  bloodstone_chunk: {
    name: "Rough Bloodstone",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["bloodstone_gem"],
  },
  emerald_chunk: {
    name: "Rough Emerald",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["emerald_gem"],
  },
  opal_chunk: {
    name: "Rough Opal",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["opal_gem"],
  },
  quartz_chunk: {
    name: "Rough Quartz",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["quartz_gem"],
  },
  sapphire_chunk: {
    name: "Rough Sapphire",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["sapphire_gem"],
  },
  diamond_chunk: {
    name: "Rough Diamond",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["diamond_gem"],
  },
  sunstone_chunk: {
    name: "Rough Sunstone",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["sunstone_gem"],
  },
  moonstone_chunk: {
    name: "Rough Moonstone",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["moonstone_gem"],
  },
  onyx_chunk: {
    name: "Rough Onyx",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["onyx_gem"],
  },
  lapisLazuli_chunk: {
    name: "Rough Lapis Lazuli",
    description: roughGemstoneDescription,
    tags: [...rawMaterialTags, "crystal"],
    refine_into: ["lapisLazuli_gem"],
  },
};
