import { ItemTag, RawWoodItemType } from "../../types/Items";
import { RawMaterialDatabase } from "./RawMaterials";

const rawMaterialTags: ItemTag[] = ["raw"];
const woodDescription =
  "Unrefined wood item. Can be cut and refined into planks.";

export const rawWoodItems: {
  [key in RawWoodItemType]: RawMaterialDatabase;
} = {
  pine_logs: {
    name: "Pine Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["pine_planks"],
  },
  spruce_logs: {
    name: "Spruce Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["spruce_planks"],
  },
  cedar_logs: {
    name: "Cedar Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["cedar_planks"],
  },
  holywood_logs: {
    name: "Holywood Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["holywood_planks"],
  },
  acacia_logs: {
    name: "Acacia Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["acacia_planks"],
  },
  oak_logs: {
    name: "Oak Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["oak_planks"],
  },
  maple_logs: {
    name: "Maple Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["maple_planks"],
  },
  yew_logs: {
    name: "Yew Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["yew_planks"],
  },
  willow_logs: {
    name: "Willow Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["willow_planks"],
  },
  hickory_logs: {
    name: "Hickory Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["hickory_planks"],
  },
  ash_logs: {
    name: "Ash Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["ash_planks"],
  },
  palm_logs: {
    name: "Palm Logs",
    description: woodDescription,
    tags: [...rawMaterialTags, "wood"],
    refine_into: ["palm_planks"],
  },
};
