import { Timestamp } from "../types/Timestamp";

export function plotCost(now: Timestamp): number {
  const releaseDateValue = 1000000;
  const releaseDateMillis = 1668124800000;
  const dayMillis = 86400000;
  const changePerDay = 2520;
  const daysSinceRelease = Math.floor(
    (now.toMillis() - releaseDateMillis) / dayMillis
  );
  return releaseDateValue + changePerDay * daysSinceRelease;
}

type Building = "Trailer";

export type Plot = {
  owner: string; // (government or player)
  level: number;
  buildData: {
    inDevelopment: boolean;
    building: Building | null;
    resources?: {
      current: number;
      needed: number;
    };
  };
  rentData?: {
    renter: string; // player
    ownerAcceptsRenewal: boolean;
    renterAcceptsRenewal: boolean;
    duration: number; // millis
    cost: number;
    start: Timestamp;
  };
};
