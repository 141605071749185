import { InventoryObject } from "../modules/Inventory";
import { Achievement, AchievementBasic } from "./Achievement";
import { BasicGovernmentData } from "./Governments";
import { Permission } from "./Permission";
import { ProfileData1 } from "./Profile";
import { Scans } from "./Scans";
import { PlayerProfessionData } from "./Skills";
import { TaskPlayer } from "./Tasks";
import { Timestamp } from "./Timestamp";
import { Title } from "./Title";

export type RegenStat = {
  current: number;
  maxMods: [];
  regenMods: [];
};

type Premium = "donation" | "subscription" | null;

export type PremiumData = {
  credit: number;
  end: Timestamp;
  type: Premium;
  useCredit: boolean;
  willRenew: boolean;
};

export type BasicData1 = {
  energy: RegenStat;
  equipment: object;
  inventory: InventoryObject;
  life: RegenStat;
  money: number;
  owner: string;
  power: RegenStat;
  premium: PremiumData;
  regenPrevious: Timestamp;
  scans: Scans;
  tasks: TaskPlayer[];
  username: string;
  version: 1;
  professions: PlayerProfessionData;
  uid?: string;
};

export function basicUpdate1to2(basic: BasicData1): BasicData2 {
  return {
    energy: basic.energy.current,
    equipment: basic.equipment,
    inventory: basic.inventory,
    life: basic.life.current,
    money: basic.money,
    owner: basic.owner,
    power: basic.power.current,
    premium: basic.premium,
    professions: basic.professions,
    regenPrevious: basic.regenPrevious,
    tasks: basic.tasks,
    username: basic.username,
    version: 2,
  };
}

export type BasicData2 = {
  energy: number;
  equipment: object;
  inventory: InventoryObject;
  life: number;
  money: number;
  owner: string;
  power: number;
  premium: PremiumData;
  regenPrevious: Timestamp;
  tasks: TaskPlayer[];
  username: string;
  version: 2;
  professions: PlayerProfessionData;
  uid?: string;
};

export function basicUpdate2to3(
  basic: BasicData2,
  profile: ProfileData1
): BasicData3 {
  return {
    createdAt: profile.createdAt,
    influence: profile.influence ?? 0,
    government: profile.government ?? null,
    permissions: profile.permissions ?? null,
    title: (profile.title as Title) ?? null,
    usernameLowered: basic.owner.toLowerCase(),
    achievements: {},

    energy: basic.energy,
    equipment: basic.equipment,
    inventory: basic.inventory,
    life: basic.life,
    money: basic.money,
    owner: basic.owner,
    power: basic.power,
    premium: basic.premium,
    professions: basic.professions,
    regenPrevious: basic.regenPrevious,
    tasks: basic.tasks,
    username: basic.username,
    version: 3,
  };
}

export type BasicData3 = {
  createdAt: Timestamp;
  influence: number;
  government: BasicGovernmentData | null;
  permissions: Permission[] | null;
  title: Title | null;
  usernameLowered: string;
  achievements: { [key in Achievement]?: AchievementBasic };

  energy: number;
  equipment: object;
  inventory: InventoryObject;
  life: number;
  money: number;
  owner: string;
  power: number;
  premium: PremiumData;
  regenPrevious: Timestamp;
  tasks: TaskPlayer[];
  username: string;
  version: 3;
  professions: PlayerProfessionData;
  uid?: string;
};

export type BasicData = BasicData3;

const noTime: Timestamp = { seconds: 0, nanoseconds: 0, toMillis: () => 0 };

export const emptyBasicData: BasicData = {
  createdAt: noTime,
  influence: 0,
  government: null,
  permissions: null,
  title: null,
  usernameLowered: "loading",
  achievements: {},

  energy: 0,
  equipment: {},
  inventory: {},
  life: 0,
  money: 0,
  owner: "loading...",
  power: 0,
  premium: {
    credit: 0,
    end: noTime,
    type: null,
    useCredit: true,
    willRenew: false,
  },
  professions: {},
  regenPrevious: noTime,
  tasks: [],
  username: "loading...",
  version: 3,
};

export const currentBasicVersion = 3;

export type AnyBasicData = BasicData1 | BasicData2 | BasicData3;
