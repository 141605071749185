import "./NotFound.css";

export default function NotFound() {
  return (
    <>
      <h1>Page Not Found</h1>
      <p className="game-notice">
        Your nav and comms begin to malfunction. You should head back before you
        get lost out here.
      </p>
    </>
  );
}
