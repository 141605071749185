import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { craftingShapeItems } from "../../data/Items/CraftingShapes";
import { functions } from "../../firebase/config";
import { useBasicContext } from "../../hooks/useBasicContext";
import { CraftingShapeType, ItemType } from "../../types/Items";
import { TaskRequest } from "../../types/Tasks";

// Styles
import "./Crafting.css";

/**TODO:
 * Needs a filterable inventory component
 * Select a resource from that component. (ItemType and ID)
 */

export default function Crafting() {
  // const { basic } = useBasicContext();
  type Selection = { type: ItemType; id: number; shape: CraftingShapeType };
  const [selection, setSelection] = useState({
    type: "aluminum_ore",
    id: 3,
    shape: "crossbow_template",
  } as Selection);
  const { basic, error } = useBasicContext();

  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as null | string);
  const [material, setMaterial] = useState("" as ItemType | "");
  const [materialIsValid, setMaterialIsValid] = useState(false);
  const [level, setLevel] = useState(null as number | null);
  const [levelIsValid, setLevelIsValid] = useState(false);
  const [shape, setShape] = useState("" as CraftingShapeType | "");
  const [shapeIsValid, setShapeIsValid] = useState(false);

  const handleCraft = () => {
    setIsPending(true);
    setErrorMessage(null);
    console.log(selection);
    const startTask = httpsCallable(functions, "craftItem");
    const taskRequest = {
      shape: shape,
      materialType: material,
      materialLevel: level,
    };
    startTask(taskRequest)
      .then((response) => {
        console.log("Success");
        console.log(response);
        setIsPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        setErrorMessage(error.message);
      });
  };

  const materialChange = (e: any) => {
    setMaterialIsValid(false);
    const val: ItemType = e.target.value;
    setMaterial(val);
    const mat = basic.inventory[val];
    if (mat !== undefined && mat.tags.includes("refined")) {
      setMaterialIsValid(true);
      setSelection((prevState) => {
        return { type: val, id: prevState.id, shape: prevState.shape };
      });
    }
  };

  const levelChange = (e: any) => {
    setLevelIsValid(false);
    const val: number = parseInt(e.target.value);
    setLevel(val);

    if (!materialIsValid) return;
    const mat = basic.inventory[selection.type];
    if (
      mat !== undefined &&
      mat.tags.includes("refined") &&
      mat.instances.find((instance) => {
        return instance.level === val;
      })
    ) {
      setLevelIsValid(true);
      setSelection((prevState) => {
        return { type: prevState.type, id: val, shape: prevState.shape };
      });
    }
  };

  const shapeChange = (e: any) => {
    setShapeIsValid(false);
    const val: CraftingShapeType = e.target.value;
    setShape(val);
    if (val !== undefined && craftingShapeItems[val] !== undefined) {
      setShapeIsValid(true);
      setSelection((prevState) => {
        return {
          type: prevState.type,
          id: prevState.id,
          shape: val,
        };
      });
    }
  };

  return (
    <div className="crafting">
      <h1 className="pageName">Crafting</h1>
      <label>
        <span>Material ID</span>
        <div className="valid-input">
          <span className="validity">{materialIsValid ? "✅" : "❌"}</span>
          <input
            type="text"
            disabled={isPending}
            onChange={materialChange}
            value={material}
          ></input>
        </div>
      </label>
      <label>
        <span>Item Level</span>
        <div className="valid-input">
          <span className="validity">{levelIsValid ? "✅" : "❌"}</span>

          <input
            type="number"
            disabled={isPending}
            onChange={levelChange}
            value={level ? level : ""}
          ></input>
        </div>
      </label>
      <label>
        <span>New Shape</span>
        <div className="valid-input">
          <span className="validity">{shapeIsValid ? "✅" : "❌"}</span>
          <input
            type="text"
            disabled={isPending}
            onChange={shapeChange}
            value={shape}
          ></input>
        </div>
      </label>
      {materialIsValid && levelIsValid && shapeIsValid && (
        <button className="btn" onClick={handleCraft} disabled={isPending}>
          {isPending
            ? "Loading"
            : `Craft ${selection.type}.${selection.id} into ${
                craftingShapeItems[shape as CraftingShapeType].name
              }`}
        </button>
      )}
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
}
