import { ItemTag, RawAnimalItemType } from "../../types/Items";
import { RawMaterialDatabase } from "./RawMaterials";

const carcassDescription =
  "The carcass of an animal. This item can be processed into bone and leather.";

const rawMaterialTags: ItemTag[] = ["raw"];

export const rawAnimalItems: {
  [key in RawAnimalItemType]: RawMaterialDatabase;
} = {
  rodent_carcass: {
    name: "Rodent Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["rodent_bone", "rodent_hide"],
  },
  deer_carcass: {
    name: "Deer Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["deer_bone", "deer_hide"],
  },
  shark_carcass: {
    name: "Shark Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["shark_bone", "shark_hide"],
  },
  cattle_carcass: {
    name: "Cattle Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["cattle_bone", "cattle_hide"],
  },
  mollusc_carcass: {
    name: "Mollusc Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["mollusc_bone", "mollusc_hide"],
  },
  serpent_carcass: {
    name: "Serpent Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["serpent_bone", "serpent_hide"],
  },
  fish_carcass: {
    name: "Fish Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["fish_bone", "fish_hide"],
  },
  lizard_carcass: {
    name: "Lizard Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["lizard_bone", "lizard_hide"],
  },
  horse_carcass: {
    name: "Horse Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["horse_bone", "horse_hide"],
  },
  ape_carcass: {
    name: "Ape Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["ape_bone", "ape_hide"],
  },
  cat_carcass: {
    name: "Cat Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["cat_bone", "cat_hide"],
  },
  wolf_carcass: {
    name: "Wolf Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["wolf_bone", "wolf_hide"],
  },
  raptor_carcass: {
    name: "Raptor Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["raptor_bone", "raptor_hide"],
  },
  bug_carcass: {
    name: "Bug Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["bug_bone", "bug_hide"],
  },
  fowl_carcass: {
    name: "Fowl Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["fowl_bone", "fowl_hide"],
  },
  amphibian_carcass: {
    name: "Amphibian Carcass",
    description: carcassDescription,
    tags: [...rawMaterialTags, "animal"],
    refine_into: ["amphibian_bone", "amphibian_hide"],
  },
};
