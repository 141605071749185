import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";

declare global {
  interface Number {
    toShortFormNotation: () => string;
  }
}

Number.prototype.toShortFormNotation = function (): string {
  const amount = Math.abs(this as number);
  const sign = this < 0 ? "-" : "";

  if (amount < 10000) {
    return sign + amount.toLocaleString();
  }

  // Note: Numbers cannot not store integers safely above 9 Quadrillion.
  const suffixes = [
    "",
    "K",
    "M",
    "B",
    "T",
    "Qa",
    "Qi",
    "Se",
    "Sp",
    "Oc",
    "No",
    "De",
  ];

  // Note: More generally, we should check sign and if 0, but
  // our numbers will be greater than or equal to positive 10k here
  const places = Math.floor(Math.log10(amount));
  const index = Math.floor(places / 3);
  if (index >= suffixes.length) throw new Error("Value out of bounds");
  const suffix = suffixes[index];

  // Round to 3 digits
  const showAmount = (amount / Math.pow(1000, index)).toPrecision(3);

  return sign + showAmount + suffix;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
