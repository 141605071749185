import { limit, orderBy, where } from "firebase/firestore";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { useDocument } from "../../hooks/useDocument";
import { GovernmentData } from "../../types/Governments";
import { ProfileData } from "../../types/Profile";

interface GovernmentDocument extends GovernmentData {
  id: string;
}

interface ProfileDocument extends ProfileData {
  id: string;
}

export default function MyGovernment(props: { govId: string }) {
  const { govId } = props;
  const { document: govDoc, error } = useDocument("governments/", govId) as {
    document?: GovernmentDocument;
    error?: string;
  };
  const { documents: citizens, error: citizensError } = useCollection(
    "players/",
    [
      where("government.id", "==", govId),
      orderBy("government.joined", "asc"),
      limit(25),
    ]
  ) as { documents: ProfileDocument[] | null; error: string | null };
  const [leaveIsPending, setLeaveIsPending] = useState(false);
  const [leaveError, setLeaveError] = useState(null as string | null);

  const handleLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLeaveIsPending(true);
    const govLeave = httpsCallable(
      functions,
      "governmentLeave"
    ) as HttpsCallable<undefined, unknown>;
    govLeave()
      .then((response) => {
        console.log(response);
        setLeaveIsPending(false);
      })
      .catch((error) => {
        setLeaveError(error.message);
        console.error(error);
        setLeaveIsPending(false);
      });
  };

  return (
    <>
      {!govDoc && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {govDoc && (
        <>
          <h2>{govDoc.name}</h2>
          <p>{new Date(govDoc.createdAt.toMillis()).toLocaleDateString()}</p>
          <p>Influence: {govDoc.influence + govDoc.citizenInfluence}</p>
          <p>Citizens: {govDoc.citizenCount}</p>
          <button
            className="btn"
            disabled={leaveIsPending}
            onClick={handleLeave}
          >
            Leave
          </button>
        </>
      )}
      {citizensError && <p>{citizensError}</p>}
      {citizens && (
        <>
          <br />
          <br />
          <h3>Citizens</h3>
          <ul>
            {citizens.map((citizen) => {
              return (
                <li key={citizen.id}>
                  <p>
                    {citizen.username} ({citizen.government!.role})
                  </p>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
}
