import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../../firebase/config";
import { TaskId, TaskData } from "../../../types/Tasks";

export default function TaskTestButton(props: {
  task: TaskId;
  children: any;
  selection?: any;
  className?: string;
}) {
  const { className, task, selection, children } = props;

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null as string | null);

  const handleOnclick = async () => {
    setIsPending(true);
    setError(null);

    try {
      const startTask = httpsCallable(functions, task);
      const response = (await startTask(selection)) as HttpsCallableResult<any>;
      console.log(response);
      if (response.data.error !== undefined)
        throw new Error(response.data.error);
    } catch (e) {
      const error = e as Error;
      setError(error.message);
    }

    setIsPending(false);
  };

  return (
    <>
      <button
        className={className ?? "btn"}
        disabled={isPending}
        onClick={(e) => {
          handleOnclick();
        }}
      >
        {children}
      </button>
      {error && <p className="error">{error}</p>}
    </>
  );
}
