import { NavLink } from "react-router-dom";
import { useBasicContext } from "../../hooks/useBasicContext";
import { hasPermission } from "../../modules/HasPermission";

// styles
import "./SideBar.css";

export default function SideBar() {
  const { basic } = useBasicContext();

  return (
    <div className="side-bar">
      <div className="side-bar-content">
        {basic && (
          <>
            {basic.uid && (
              <NavLink className="username" to={`/players/${basic.uid}`}>
                {basic!.username}
              </NavLink>
            )}
            <p>Energy: {basic!.energy.toLocaleString()}/5040</p>
            <p>Money: {basic!.money.toShortFormNotation()}$</p>
          </>
        )}
      </div>
      <nav className="links">
        <ul>
          {basic.permissions && hasPermission(basic, "staff") && (
            <li>
              <NavLink to="/dev-tools">
                {/* <img src={} alt="" /> */}
                <span>Staff Links</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/announcements">
              {/* <img src={} alt="" /> */}
              <span>Announcements</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/government">
              {/* <img src={} alt="" /> */}
              <span>Government</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard">
              {/* <img src={} alt="" /> */}
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/homestead">
              <span>Homestead</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/scans">
              <span>Scans</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/inventory">
              <span>Inventory</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/refining">
              <span>Refining</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/crafting">
              <span>Crafting</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/professions">
              <span>Professions</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/logs">
              <span>Logs</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
