// TODO: Convert page for scanning, add all scanning types into here

// styles
import "./Scans.css";
import StartScanButton from "./StartScanButton";

export default function Scans() {
  return (
    <div className="scans">
      <h1 className="page-title">
        [WIP] This page is currently under construction and may not operate as
        expected
      </h1>

      <p className="warning">
        This page is undergoing changes due to changes in how scans work.
      </p>

      <StartScanButton task="animalScan">Animal Scan</StartScanButton>
      <br />
      <StartScanButton task="crystalScan">Crystal Scan</StartScanButton>
      <br />
      <StartScanButton task="metalScan">Metal Scan</StartScanButton>
      <br />
      <StartScanButton task="woodScan">Wood Scan</StartScanButton>
    </div>
  );
}
