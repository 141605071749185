import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAwHcVzOcp3XiqNu8NJmJCZsBEoyjT-Wo",
  authDomain: "sol-dev-add25.firebaseapp.com",
  projectId: "sol-dev-add25",
  storageBucket: "sol-dev-add25.appspot.com",
  messagingSenderId: "838413432732",
  appId: "1:838413432732:web:24bcb4626a47c3d6847a59",
};

// Init firebase
initializeApp(firebaseConfig);

// init services
const auth = getAuth();
const firestore = getFirestore();
const functions = getFunctions();

export { auth, firestore, functions };
