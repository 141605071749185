import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { ProfileData } from "../../types/Profile";
import CreateGovernment from "./CreateGovernment";
import "./Government.css";
import JoinGovernment from "./JoinGovernment";
import MyGovernment from "./MyGovernment";

interface ProfileDocument extends ProfileData {
  id: string;
}

export default function Government() {
  const { user } = useAuthContext();
  const uid = user!.uid; // User is signed in because this is an auth route.
  const { document: userProfile, error } = useDocument("players/", uid) as {
    document?: ProfileDocument;
    error?: string;
  };

  return (
    <>
      <h1>Government</h1>
      {error && <p className="error">{error}</p>}
      {userProfile && userProfile.government && (
        <MyGovernment govId={userProfile.government.id} />
      )}
      {userProfile && !userProfile.government && (
        <>
          <JoinGovernment />
          <br />
          <CreateGovernment />
        </>
      )}
    </>
  );
}
