import { ItemTag, RawMetalItemType } from "../../types/Items";
import { RawMaterialDatabase } from "./RawMaterials";

const oreDescription =
  "This is a unrefined metal item. It can smelted into ingots at a metal refinery. Ingots can be used to craft items.";

const oreTags: ItemTag[] = ["raw", "metal"];

export const rawMetalItems: {
  [key in RawMetalItemType]: RawMaterialDatabase;
} = {
  titanium_ore: {
    name: "Titanium Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["titanium_ingot"],
  },
  iron_ore: {
    name: "Iron Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["iron_ingot"],
  },
  aluminum_ore: {
    name: "Aluminum Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["aluminum_ingot"],
  },
  tungsten_ore: {
    name: "Tungsten Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["tungsten_ingot"],
  },
  cobalt_ore: {
    name: "Cobalt Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["cobalt_ingot"],
  },
  nickel_ore: {
    name: "Nickel Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["nickel_ingot"],
  },
  platinum_ore: {
    name: "Platinum Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["platinum_ingot"],
  },
  gold_ore: {
    name: "Gold Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["gold_ingot"],
  },
  silver_ore: {
    name: "Silver Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["silver_ingot"],
  },
  copper_ore: {
    name: "Copper Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["copper_ingot"],
  },
  tin_ore: {
    name: "Tin Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["tin_ingot"],
  },
  zinc_ore: {
    name: "Zinc Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["zinc_ingot"],
  },
  lead_ore: {
    name: "Lead Ore",
    description: oreDescription,
    tags: [...oreTags],
    refine_into: ["lead_ingot"],
  },
};
