import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { TaskId, TaskRequest } from "../../types/Tasks";

// TODO: Adapt to work for all tasks

export default function StartScanButton(params: {
  children: string;
  task: TaskId;
}) {
  const { task, children } = params;

  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as null | string);

  const handleStartTask = () => {
    setIsPending(true);
    setErrorMessage(null);
    const startTask = httpsCallable(functions, task);
    startTask()
      .then((response) => {
        console.log("Success");
        console.log(response);
        setIsPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        setErrorMessage(error.message);
      });
  };
  return (
    <>
      <button className="btn" onClick={handleStartTask} disabled={isPending}>
        {isPending ? "Loading" : children}
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </>
  );
}
