import { useEffect, useState } from "react";
import { firestore } from "../firebase/config";
import { onSnapshot, doc } from "firebase/firestore";

export function useDocument(col: string, id: string) {
  const [document, setDocument] = useState(null as any);
  const [error, setError] = useState(null as null | string);

  // realtime data for document
  useEffect(() => {
    const docRef = doc(firestore, col + id);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setDocument({ ...snapshot.data(), id: snapshot.id });
          setError(null);
        } else setError("No such document exists");
      },
      (error) => {
        console.log(error.message);
        setError("Failed to get document");
      }
    );

    return () => unsubscribe();
  }, [col, id]);

  return { document, error };
}
