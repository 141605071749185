/** TODO:
 * build inventory
 * build harvesting function
 * timer for when regeneration is due
 * if in focus, and time for regen, call regen function
 * create regen function
 */

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// Styles
import "./App.css";

// Pages
import Landing from "./pages/landing/Landing";
import Register from "./pages/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import Scans from "./pages/scans/Scans";
import Inventory from "./pages/inventory/Inventory";
import Crafting from "./pages/crafting/Crafting";

// Components
import TopBar from "./components/topBar/TopBar";
import SideBar from "./components/sideBar/SideBar";
import { BasicContextProvider } from "./context/BasicContext";
import Refining from "./pages/refining/Refining";
import Professions from "./pages/professions/Professions";
import Logs from "./pages/logs/Logs";
import Announcements from "./pages/announcements/Announcements";
import PublicChat from "./components/publicChat/PublicChat";
import PeriodicAutoUpdater from "./components/PeriodicAutoUpdater";
import { useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase/config";
import Homestead from "./pages/homestead/Homestead";
import Government from "./pages/government/Government";
import SendMoney from "./pages/sendMoney/SendMoney";
import NotFound from "./pages/notFound/NotFound";
import UserProfile from "./pages/userProfile/UserProfile";
import DevRoute from "./components/devRoute/DevRoute";
import DevIndex from "./pages/_devPages/DevIndex";
import { ProfileContextProvider } from "./context/ProfileContext";
import AvatarUpload from "./pages/_devPages/avatarUpload/AvatarUpload";
import TaskTest from "./pages/_devPages/taskTest/TaskTest";

function App() {
  const { user, authIsReady } = useAuthContext();

  useEffect(() => {
    if (authIsReady && user) {
      const update = httpsCallable(functions, "regenerate");
      update();
    }
  }, [user]);

  return (
    <div className="App">
      {authIsReady && (
        <BasicContextProvider>
          <ProfileContextProvider>
            <BrowserRouter>
              {user && <SideBar />}
              <div className="container">
                <TopBar />
                {user && <PeriodicAutoUpdater />}
                <div className="page-structure">
                  <div
                    className={!user ? "page-content no-chat" : "page-content"}
                  >
                    <Routes>
                      {/* No Auth Routes 📂 */}
                      <Route
                        path="/"
                        element={
                          !user ? <Landing /> : <Navigate to="/dashboard" />
                        }
                      />
                      <Route
                        path="/register"
                        element={
                          !user ? <Register /> : <Navigate to="/dashboard" />
                        }
                      />

                      {/* Auth Routes 🔒 */}
                      <Route
                        path="/announcements"
                        element={user ? <Announcements /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/dashboard"
                        element={user ? <Dashboard /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/government"
                        element={user ? <Government /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/homestead"
                        element={user ? <Homestead /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/scans"
                        element={user ? <Scans /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/inventory"
                        element={user ? <Inventory /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/refining"
                        element={user ? <Refining /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/crafting"
                        element={user ? <Crafting /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/professions"
                        element={user ? <Professions /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/send-money"
                        element={user ? <SendMoney /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/logs"
                        element={user ? <Logs /> : <Navigate to="/" />}
                      />
                      <Route
                        path="/players/:id"
                        element={user ? <UserProfile /> : <Navigate to="/" />}
                      />

                      {/* Developer Routes 🔨 */}
                      <Route
                        path="/dev-tools"
                        element={
                          user ? (
                            <DevRoute
                              permission="staff"
                              element={<DevIndex />}
                            />
                          ) : (
                            <Navigate to="/" />
                          )
                        }
                      />
                      <Route
                        path="/dev-tools/profile-image-uploader"
                        element={
                          user ? (
                            <DevRoute
                              permission="uploadProfileImage"
                              element={<AvatarUpload />}
                            />
                          ) : (
                            <Navigate to="/" />
                          )
                        }
                      />
                      <Route
                        path="/dev-tools/tasks"
                        element={
                          user ? (
                            <DevRoute
                              permission="administrator"
                              element={<TaskTest />}
                            />
                          ) : (
                            <Navigate to="/" />
                          )
                        }
                      />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                  {user && (
                    <div className="chat-system">
                      <PublicChat />
                    </div>
                  )}
                </div>
              </div>
            </BrowserRouter>
          </ProfileContextProvider>
        </BasicContextProvider>
      )}
    </div>
  );
}

export default App;
