import { BasicData } from "../types/Basic";
import { Permission } from "../types/Permission";
import { ProfileData } from "../types/Profile";

export function hasPermission(
  basic: BasicData | undefined,
  permission?: Permission
): boolean {
  return (
    (basic &&
      basic.permissions &&
      ((permission && basic.permissions.includes(permission)) ||
        basic.permissions.includes("administrator"))) ??
    false
  );
}
