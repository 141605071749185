// import { TaskDatabase } from "../types/Tasks";

const experienceRequiredForLevelOne = 60000000; // xp for first level (increment amount)

export function experienceToLevel(xp: number): number {
  const n = experienceRequiredForLevelOne;
  const sqrt = Math.sqrt(n * (n + 8 * xp));
  return Math.floor(-(n - sqrt) / (2 * n));
}

export function levelToExperience(level: number): number {
  const n = experienceRequiredForLevelOne;
  return (n * level * (level + 1)) / 2;
}

// export function taskExperience(
//   taskData: TaskDatabase,
//   iterations: number = 1
// ): number {
//   const energyMultiplier = 30000; // 1min/2energy * 60sec/min * 1000ms/sec * 1xp/ms => 30000 xp/energy

//   let totalEnergyCost = taskData.costs.energy?.amount ?? 0;
//   // taskData.costs.forEach((cost) => {
//   //   if (cost.resource === "energy") {
//   //     totalEnergyCost += cost.amount;
//   //   }
//   // });

//   const experienceFromEnergy = totalEnergyCost * energyMultiplier * iterations;
//   const experienceFromTime = taskData.duration * iterations;

//   const systemXpMulti = taskData.experienceMultiplier ?? 1;
//   return (experienceFromEnergy + experienceFromTime) * systemXpMulti;
// }
