import { ItemTag, RefinedMetalType } from "../../types/Items";
import { RefinedMaterialDatabase } from "./RefinedMaterials";

const metalTags: ItemTag[] = ["metal"];
const ingotTags: ItemTag[] = [...metalTags, "refined"];

export const refinedMetalItems: {
  [key in RefinedMetalType]: RefinedMaterialDatabase;
} = {
  titanium_ingot: {
    name: "Titanium Ingot",
    description: "",
    craftedDescription:
      "is the apex of structural metal thanks to its unrivaled light weight that overshadows its lack of resilience or malleability. ",
    tags: [...ingotTags],
    attributes: { actions: 4, base: 0, critical: 0, detail: 0 },
    elements: { cold: 1, corrosive: 2, visceral: 4, poison: 1 },
    craftedName: "Titanium",
    craftedTags: [...metalTags],
  },
  iron_ingot: {
    name: "Iron Ingot",
    description: "",
    craftedDescription:
      "provides superior ease of use relative to other metals while still being somewhat durable.",
    tags: [...ingotTags],
    attributes: { actions: 3, base: 1, critical: 0, detail: 0 },
    elements: { electric: 2, bio: 1, visceral: 8, spatial: 1 },
    craftedName: "Iron",
    craftedTags: [...metalTags],
  },
  aluminum_ingot: {
    name: "Aluminum Ingot",
    description: "",
    craftedDescription:
      "makes for a notably lighter metal that is easy to use though not as durable as other choices.",
    tags: [...ingotTags],
    attributes: { actions: 3, base: 0, critical: 1, detail: 0 },
    elements: {
      fire: 1,
      electric: 1,
      mind: 2,
      chaos: 1,
      visceral: 3,
      radiation: 1,
      miasma: 1,
    },
    craftedName: "Aluminum",
    craftedTags: [...metalTags],
  },
  tungsten_ingot: {
    name: "Tungsten Ingot",
    description: "",
    craftedDescription:
      "provides unrivaled strength and power while having considerable commercial uses on many worlds.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 3, critical: 0, detail: 1 },
    elements: { fire: 3, visceral: 5 },
    craftedName: "Tungsten",
    craftedTags: [...metalTags],
  },
  cobalt_ingot: {
    name: "Cobalt Ingot",
    description: "",
    craftedDescription:
      "is extremely durable. Though heavier than iron, it is lighter than other industrial materials.",
    tags: [...ingotTags],
    attributes: { actions: 1, base: 3, critical: 0, detail: 0 },
    elements: { mind: 1, dark: 4, nether: 1 },
    craftedName: "Cobalt",
    craftedTags: [...metalTags],
  },
  nickel_ingot: {
    name: "Nickel Ingot",
    description: "",
    craftedDescription:
      "allows it to be strong and reliable while being quite ductile and malleable.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 2, critical: 2, detail: 0 },
    elements: { sound: 1, mind: 2, aether: 1, corrosive: 5, visceral: 10 },
    craftedName: "Nickel",
    craftedTags: [...metalTags],
  },
  platinum_ingot: {
    name: "Platinum Ingot",
    description: "",
    craftedDescription:
      "is the most precious of all precious metals. It is uniquely dense and ductile, making it universally valuable.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 0, critical: 1, detail: 3 },
    elements: {
      sound: 1,
      mind: 1,
      corrosive: 1,
      spiritual: 2,
      nether: 2,
      seismic: 2,
      ethereal: 1,
    },
    craftedName: "Platinum",
    craftedTags: [...metalTags],
  },
  gold_ingot: {
    name: "Gold Ingot",
    description: "",
    craftedDescription:
      "is legendary for its high value across all worlds. Gold is famous for its malleability and is notably soft.",
    tags: [...ingotTags],
    attributes: { actions: 1, base: 0, critical: 1, detail: 2 },
    elements: { fire: 1, electric: 1, temporal: 1, light: 1 },
    craftedName: "Gold",
    craftedTags: [...metalTags],
  },
  silver_ingot: {
    name: "Silver Ingot",
    description: "",
    craftedDescription:
      "provides stunning brilliance and lustre. As a precious metal, silver's ease to work with is legendary, plus it is somewhat stronger than other precious metals. Silver is known on many worlds for sterility and having a unique relationship with life, light, and spirituality.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 1, critical: 1, detail: 2 },
    craftedName: "Silver",
    craftedTags: [...metalTags],
    elements: {
      cold: 1,
      mind: 1,
      dark: 1,
      aether: 2,
      bio: 2,
      light: 2,
      nether: 2,
      ethereal: 1,
    },
  },
  copper_ingot: {
    name: "Copper Ingot",
    description: "",
    craftedDescription:
      "is made of a reliable precious metal used for many things thanks to the ease of which it can be worked.",
    tags: [...ingotTags],
    attributes: { actions: 2, base: 0, critical: 0, detail: 0 },
    elements: { electric: 3, corrosive: 1, visceral: 1, radiation: 1 },
    craftedName: "Copper",
    craftedTags: [...metalTags],
  },
  tin_ingot: {
    name: "Tin Ingot",
    description: "",
    craftedDescription:
      "is malleable and very light. Tin has decent demand thanks to its applications in alloying.",
    tags: [...ingotTags],
    attributes: { actions: 2, base: 0, critical: 1, detail: 1 },
    elements: { sound: 2, visceral: 1, seismic: 1 },
    craftedName: "Tin",
    craftedTags: [...metalTags],
  },
  zinc_ingot: {
    name: "Zinc Ingot",
    description: "",
    craftedDescription:
      "suffers from being somewhat brittle in controlled conditions but excels in many of the random magical or high temperature micro-weather phenomena that regularly occur.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 2, critical: 1, detail: 1 },
    elements: { fire: 1, dark: 1, temporal: 1, visceral: 4, spatial: 1 },
    craftedName: "Zinc",
    craftedTags: [...metalTags],
  },
  lead_ingot: {
    name: "Lead Ingot",
    description: "",
    craftedDescription:
      "suffers from being somewhat brittle in controlled conditions but excels in many of the random magical or high temperature micro-weather phenomena that regularly occur.",
    tags: [...ingotTags],
    attributes: { actions: 0, base: 2, critical: 0, detail: 2 },
    elements: { fire: 1, mind: 3, visceral: 3, poison: 8, miasma: 3 },
    craftedName: "Lead",
    craftedTags: [...metalTags],
  },
};
