import { DatabaseItem, ItemTag, ItemType } from "../types/Items";
import { craftingShapeItems } from "./Items/CraftingShapes";
import { rawMaterialItems } from "./Items/RawMaterials";
import { refinedMaterialItems } from "./Items/RefinedMaterials";
import { scanItems } from "./Items/Scans";

type ItemsCollectionData = {
  [key in ItemType]: DatabaseItem;
};

export const allItems: ItemsCollectionData = {
  ...scanItems,
  ...refinedMaterialItems,
  ...rawMaterialItems,
  ...craftingShapeItems,
  // yargh: {
  //   name: "Yargh",
  //   description:
  //     "This treasure chest is small, but it's still packed full of goodies! Inside you'll find all sorts of treasures, from gold and jewels to rare weapons and armor. This is the perfect cache for a party of adventurers looking to make a big score!",
  //   tags: supplyTags,
  // },
};

type ItemsCollectionSubset = {
  [key in ItemType]?: DatabaseItem;
};

export function filterItemsByTag(
  items: ItemsCollectionSubset,
  tag: ItemTag
): ItemsCollectionSubset {
  let filteredItems: ItemsCollectionSubset = {};
  for (const [_item, data] of Object.entries(items)) {
    const item = _item as ItemType;
    if (data.tags.includes(tag)) {
      filteredItems[item] = data;
    }
  }
  return filteredItems;
}

const allItemTypes: ItemType[] = Object.keys(allItems) as ItemType[];

export function doesItemExist(itemId: ItemType) {
  return allItemTypes.includes(itemId);
}
