import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { BasicData, emptyBasicData } from "../types/Basic";
import { firestore } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";

export const BasicContext = createContext({
  basic: emptyBasicData,
  error: null as null | string,
});

export const BasicContextProvider = ({ children }: { children: any }) => {
  const { user } = useAuthContext();
  const [basic, setBasic] = useState(emptyBasicData);
  const [error, setError] = useState(null as null | string);

  useEffect(() => {
    if (!user) {
      setBasic(emptyBasicData);
      return;
    }

    const basicRef = doc(firestore, `players/${user.uid}/data/basic`);

    const unsubscribe = onSnapshot(
      basicRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setBasic(snapshot.data() as BasicData);
          setError(null);
        } else {
          setError("Could not retrieve player data");
        }
      },
      (error) => {
        console.error(error.message);
        setError("Failed to get player data");
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <BasicContext.Provider
      value={{ basic: { ...basic, uid: user?.uid }, error }}
    >
      {children}
    </BasicContext.Provider>
  );
};
