import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { useBasicContext } from "../../hooks/useBasicContext";
import { levelToExperience } from "../../modules/Skills";
import { Profession, SkillId } from "../../types/Skills";
import "./Professions.css";

export default function Professions() {
  const { basic } = useBasicContext();
  const professions = basic.professions;
  const professionsKeys = Object.keys(professions);
  const xpDenominator = 60000;
  const userFriendlyXp = (xp: number): string => {
    return Math.floor(xp / xpDenominator).toLocaleString();
  };

  const [errorMessage, setErrorMessage] = useState(null as string | null);
  const [isPending, setIsPending] = useState(false);

  const handleBuySkill = () => {
    setIsPending(true);
    setErrorMessage(null);
    const buySkill = httpsCallable(functions, "buySkill");
    const taskRequest: { skillId: SkillId } = {
      skillId: "craftingApprentice",
    };
    buySkill(taskRequest)
      .then((response) => {
        console.log("Success");
        console.log(response);
        setIsPending(false);
        const data = response.data as any;
        if (data.error !== undefined) {
          setErrorMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      <h1>Professions</h1>
      <button className="btn" disabled={isPending} onClick={handleBuySkill}>
        Buy Skill
      </button>
      <br />
      <br />
      {professionsKeys.length === 0 && (
        <p>
          You have not earned experience in any profession yet! You will be
          given experience on completing any task.
        </p>
      )}
      <ul>
        {professionsKeys.length > 0 &&
          professionsKeys.sort().map((profession) => {
            const professionData = professions[profession as Profession];

            if (professionData === undefined) return;

            const prevLevelXp = levelToExperience(professionData.level - 1);
            const currentLevelXp = levelToExperience(professionData.level);
            const nextLevelXp = levelToExperience(professionData.level + 1);
            const progression = Math.floor(
              ((professionData.experience - currentLevelXp) /
                (nextLevelXp - currentLevelXp)) *
                100
            );
            const progressionTotal = Math.floor(
              (professionData.experience / nextLevelXp) * 100
            );

            return (
              <li className="profession-card" key={profession}>
                <h2 className="profession-title">{profession}</h2>
                <ul>
                  <li>Level: {professionData.level}</li>
                  <li>
                    Experience: {userFriendlyXp(professionData.experience)}
                  </li>
                  <li>Next Level: {userFriendlyXp(nextLevelXp)}</li>
                  <li></li>
                  <li>Skill points: {professionData.unusedSkillPoints}</li>
                  <li>Progression: {progression}%</li>
                </ul>
              </li>
            );
          })}
      </ul>
    </>
  );
}
