import { FieldValue } from "firebase/firestore";
import { Timestamp } from "./Timestamp";

export type GovernmentRole = "Leader" | "Citizen";

export type BasicGovernmentData = {
  id: string;
  role: GovernmentRole;
  joined: Timestamp;
};

export type CreateGovernmentData = {
  name: string;
};

export type GovernmentData = {
  citizenCount: number;
  citizenInfluence: number; // The amount of influence brought from members
  createdAt: Timestamp;
  influence: number; // Amount of influence the government owns
  name: string;
  nameLowered: string;
  owner: string;
};

// export const citizenInfluence = (govData: GovernmentData): number => {
//   let count = 0;
//   govData.citizenInfluences.forEach((element) => {
//     count += element.amount;
//   });
//   return count;
// };

export type GovernmentCitizenUpdate = {
  citizenCount: FieldValue;
  citizenInfluence: FieldValue;
};

export type JoinGovernmentData = {
  id: string;
};

const minChars = 5;
const maxChars = 25;
const nameFormat = /^[A-Za-z 0-9]+$/;
const formatError =
  "Government name may only contain letters A-Z and a-z, numbers 0-9, and [space]";

export const govNameClean = (name: string): string => {
  const newName = name;
  return newName.replace(/\s+/g, " ").trim();
};

export const validateGovName = (name: string): boolean => {
  console.log(name, name.length);
  if (name.length < minChars || name.length > maxChars) {
    throw `Government name must be inclusively between ${minChars} and ${maxChars} characters.`;
  }
  if (!nameFormat.test(name)) {
    throw formatError;
  }

  return true;
};
