import { ItemTag, RefinedWoodType } from "../../types/Items";
import { RefinedMaterialDatabase } from "./RefinedMaterials";

const woodTags: ItemTag[] = ["wood"];
const refinedMaterialTags: ItemTag[] = ["refined"];

export const refinedWoodItems: {
  [key in RefinedWoodType]: RefinedMaterialDatabase;
} = {
  pine_planks: {
    name: "Pine Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 3, base: 0, critical: 0, detail: 1 },
    elements: { cold: 1, bio: 3, spiritual: 3, poison: 1 },
    craftedName: "Pine",
    craftedTags: [...woodTags],
    craftedDescription:
      "is universally recognized as a valuable material for speed, even among all softwoods, because of its light weight and natural beauty.",
  },
  spruce_planks: {
    name: "Spruce Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 3, base: 1, critical: 0, detail: 0 },
    elements: { fire: 1, cold: 1, dark: 1, bio: 2 },
    craftedName: "Spruce",
    craftedTags: [...woodTags],
    craftedDescription:
      "outclasses other softwoods in speed except for pine and provides increased strength in return.",
  },
  cedar_planks: {
    name: "Cedar Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 2, base: 1, critical: 1, detail: 0 },
    elements: { cold: 5, sound: 2, dark: 1, bio: 2, spiritual: 1, ethereal: 1 },
    craftedName: "Cedar",
    craftedTags: [...woodTags],
    craftedDescription:
      "cannot be understated in versatility. Light as softwoods tend to be, it is still quite strong and elegant.",
  },
  holywood_planks: {
    name: "Holywood Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 0, base: 3, critical: 1, detail: 0 },
    elements: { bio: 5, visceral: 2, spiritual: 1, ethereal: 1 },
    craftedName: "Holywood",
    craftedTags: [...woodTags],
    craftedDescription:
      "is an ironwood which means it is heavier than water. It has higher strength and reliability thanks to its density.",
  },
  acacia_planks: {
    name: "Acacia Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 1, base: 2, critical: 0, detail: 1 },
    elements: { bio: 2, radiation: 1 },
    craftedName: "Acacia",
    craftedTags: [...woodTags],
    craftedDescription:
      "provides improved strength and durability. Light for an ironwood, it's also considered quite beautiful on many worlds.",
  },
  oak_planks: {
    name: "Oak Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 0, base: 1, critical: 2, detail: 1 },
    elements: { aether: 1, bio: 6, temporal: 1, spiritual: 2 },
    craftedName: "Oak",
    craftedTags: [...woodTags],
    craftedDescription:
      "makes for a prized and resilient hardwood that adds natural beauty. It is perfect for precision use.",
  },
  maple_planks: {
    name: "Maple Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 1, base: 0, critical: 1, detail: 2 },
    elements: { cold: 1, mind: 3, bio: 3, miasma: 1 },
    craftedName: "Maple",
    craftedTags: [...woodTags],
    craftedDescription:
      "is notably beautiful. Maple wood boasts remarkable versatility although it lacks strength compared to ash or other hardwoods.",
  },
  yew_planks: {
    name: "Yew Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 2, base: 2, critical: 0, detail: 0 },
    elements: { bio: 4, temporal: 1, corrosive: 1 },
    craftedName: "Yew",
    craftedTags: [...woodTags],
    craftedDescription:
      "is often mistaken for a hardwood thanks to its durability and high density, though it remains exceptionally light and powerful.",
  },
  willow_planks: {
    name: "Willow Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 2, base: 0, critical: 2, detail: 0 },
    elements: { sound: 2, aether: 2, bio: 1, miasma: 1 },
    craftedName: "Willow",
    craftedTags: [...woodTags],
    craftedDescription:
      "enjoys the flexibility and precision that all hardwoods do while still being extremely lightweight.",
  },
  hickory_planks: {
    name: "Hickory Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 0, base: 2, critical: 2, detail: 0 },
    elements: { bio: 1, visceral: 1, spatial: 1 },
    craftedName: "Hickory",
    craftedTags: [...woodTags],
    craftedDescription:
      "is very tough. The wood's density is abnormal even among hardwoods, though it is just as easy to work with.",
  },
  ash_planks: {
    name: "Ash Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 1, base: 1, critical: 1, detail: 1 },
    elements: { fire: 2, mind: 1, bio: 1 },
    craftedName: "Ash",
    craftedTags: [...woodTags],
    craftedDescription:
      "is perfectly balanced. It is light, tough, smooth, and aesthetically pleasing all in equal measure unlike any other wood or material known in the natural worlds.",
  },
  palm_planks: {
    name: "Palm Planks",
    description: "",
    tags: [...refinedMaterialTags, ...woodTags],
    attributes: { actions: 0, base: 2, critical: 0, detail: 2 },
    elements: { bio: 3, spiritual: 1, poison: 1 },
    craftedName: "Palm",
    craftedTags: [...woodTags],
    craftedDescription:
      "enjoys a beauty and value not seen in woods of most worlds.",
  },
};
