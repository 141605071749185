import { BasicGovernmentData } from "./Governments";
import { Permission } from "./Permission";
import { Timestamp } from "./Timestamp";

export type ProfileData1 = {
  createdAt: Timestamp;
  influence: number;
  government: BasicGovernmentData | null;
  owner: string;
  permissions: Permission[] | null;
  public: true;
  title: string | null;
  username: string;
  usernameLowered: string;
};

export type ProfileData = ProfileData1;

export const emptyProfileData: ProfileData = {
  createdAt: { seconds: 0, nanoseconds: 0, toMillis: () => 0 },
  influence: 0,
  government: null,
  owner: "",
  permissions: null,
  public: true,
  title: null,
  username: "",
  usernameLowered: "",
};
